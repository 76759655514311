const NAV_EXPERMENTATION_STORE = 'navExperimentationStore';
const NAV_KILLSWITCH_STORE = 'navKillswitchStore';
const NAV_PREFERRED_STORE = 'navPreferredStore';
const NAV_VIEW_TYPE_STORE = 'navViewTypeStore';
const NAV_SEARCH_SUGGESTIONS_STORE = 'navSearchSuggestionsStore';
const NAV_LOYALTY_STORE = 'navLoyaltyStore';
const NAV_QUICK_BAG_STORE = 'navQuickBag';
const NAV_OVERLAY_STORE = 'navOverlay';
const NAV_USER_STORE = 'navUser';
const NAV_REGISTRY_STORE = 'navRegistryStore';
const NAV_SHARED_STORE = 'navSharedStore';

export {
  NAV_EXPERMENTATION_STORE,
  NAV_KILLSWITCH_STORE,
  NAV_PREFERRED_STORE,
  NAV_VIEW_TYPE_STORE,
  NAV_SEARCH_SUGGESTIONS_STORE,
  NAV_LOYALTY_STORE,
  NAV_QUICK_BAG_STORE,
  NAV_OVERLAY_STORE,
  NAV_USER_STORE,
  NAV_REGISTRY_STORE,
  NAV_SHARED_STORE,
};
