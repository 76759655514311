<template>
  <Slideout
    :id="slideoutId"
    class="nav-slideout"
    @close-slideout="$emit('close-slideout')"
  >
    <div class="nav-slideout-container">
      <slot />
      <button
        aria-label="close slideout"
        class="nav-slideout-close"
        @click="toggleSlideout"
      >
        <span class="close close-large" />
      </button>
    </div>
  </Slideout>
</template>

<script setup>
import { Slideout } from '@atomic-ui/slideout';

const props = defineProps({
  slideoutId: {
    type: String,
    required: true,
  },
  provider: {
    type: Function,
    required: true,
  },
});
const { init, toggleSlideout } = props.provider();

if (init) {
  init(props.slideoutId);
}
</script>

<style lang="scss">
@import "../../scss/mixins";

.nav-slideout {
  .slideout {
    padding: 0;
  }

  .slideout-header {
    display: none;
  }

  @include breakpoint(small down) {
    .slideout {
      width: 100% !important;
    }
  }

  &-close {
    display: flex;
    position: absolute;
    right: 48px;
    top: 48px;
    cursor: pointer;
    background-color: #fff;

    @include breakpoint(medium down) {
      right: 20px;
      top: 32px;
    }
  }

  &-container {
    padding: 0 48px;
    position: relative;

    @include breakpoint(medium down) {
      padding: 0 20px;
    }
  }
}
</style>
