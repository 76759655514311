/*
  This file sets the adaptive view type of the application (desktop vs mobile)
  It will first set the view type on the server side by checking the device type
  Once it reaches the client side it will do another check for view type based on the screen size
  The screen size's view type will overwrite the device type-based view type and it will update viewtype if necessary
*/
import {
  computed, watch, watchEffect, onServerPrefetch, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { SET_VIEW_TYPE } from '../types/mutations';
import useViewType from './dumb/useViewType';
import useLocatorInit from './useLocatorInit';
import { useHeaderMenuData } from './useHeaderMenuData';

function callBackOrDispatch({
  store, callback, dispatchEvent, force = false,
}) {
  if (callback) {
    return callback(force);
  }
  return store.dispatch(dispatchEvent, force);
}

function handleViewType() {
  const store = useStore();
  const { context } = store.state.pageData.navigation;
  const { viewType } = useViewType(context);

  watchEffect(() => store.commit(`navViewTypeStore/${SET_VIEW_TYPE}`, viewType.value));
}

function fetchOnViewTypeChange({ store, dispatchEvent, callback }) {
  onMounted(() => {
    const storedType = computed(() => store.state.navViewTypeStore.viewType);

    if (storedType.value) {
      callBackOrDispatch({ store, callback, dispatchEvent });
    }

    watch(storedType, () => storedType.value && callBackOrDispatch({ store, callback, dispatchEvent }));
  });
}

function fetchOnRegionChange({ store, dispatchEvent, callback }) {
  const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
  const force = true;

  watch(isDomestic, () => callBackOrDispatch({
    store, callback, dispatchEvent, force,
  }));
}

function handleFetch({
  dispatchEvent, callback, checkViewTypeChange = true,
}) {
  const store = useStore();

  if (checkViewTypeChange) {
    fetchOnViewTypeChange({ store, dispatchEvent, callback });
  } else {
    onMounted(() => {
      callBackOrDispatch({ store, callback, dispatchEvent });
    });
  }
  fetchOnRegionChange({ store, dispatchEvent, callback });
  onServerPrefetch(() => callBackOrDispatch({ store, callback, dispatchEvent }));
}

export function useInitHeader() {
  const store = useStore();
  const { fetch } = useHeaderMenuData(store);
  useLocatorInit();
  handleViewType();
  handleFetch({ callback: fetch });
}

export function useInitFooter() {
  handleFetch({ dispatchEvent: 'footerStore/fetch', checkViewTypeChange: false });
}
