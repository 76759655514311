<template>
  <LinkRailMenu>
    <RegistryDropdown v-if="isRegistryDropdown">
      Gift Registry
    </RegistryDropdown>
    <LinkRailMenuItem v-else>
      <Link
        class="link-rail-item"
        :href="Links.homepage.registry.url"
        @click="tagRegistryLink"
      >
        Gift Registry
      </Link>
    </LinkRailMenuItem>
    <StoreLocatorDropdown />
    <AccountDropdown />
  </LinkRailMenu>
</template>

<script setup>
import { computed } from 'vue';
import LinkRailMenu from './LinkRailMenu.common';
import LinkRailMenuItem from './LinkRailMenuItem.common';
import Link from '../common/Link';
import Links from '../../constants/Links';
import StoreLocatorDropdown from './StoreLocatorDropdown.mcom';
import RegistryDropdown from './RegistryDropdown.common';
import AccountDropdown from './AccountDropdown.mcom';
import useKillswitches from '../../composables/useKillswitches';
import { tagRegistryLink } from '../../features/analytics/linkRailTags';

const killswitches = useKillswitches();
const isRegistryDropdown = computed(() => !!killswitches.value.registryDropdownEnabled);
</script>
