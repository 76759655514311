import { useStore } from 'vuex';
import { computed } from 'vue';

import { NAV_EXPERMENTATION_STORE } from '../types/names';
import { GET_EXPERIMENTS } from '../features/experimentation/types';

export default function useExperiments() {
  const store = useStore();

  return computed(() => store.getters[`${NAV_EXPERMENTATION_STORE}/${GET_EXPERIMENTS}`]);
}
