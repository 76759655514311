/* eslint-disable no-shadow */
import { SET_SHOW_INTERNATIONAL_CONTEXT_FORM, SET_SLIDEOUT_ID, SET_POPUP_MEDIA_CONTENT } from '../../types/mutations';

export function state() {
  return {
    showInternationalContextForm: false,
    slideoutId: '',
    popupMediaContent: null,
  };
}

export const mutations = {
  [SET_SHOW_INTERNATIONAL_CONTEXT_FORM](state, payload) {
    state.showInternationalContextForm = payload;
  },
  [SET_SLIDEOUT_ID](state, payload) {
    state.slideoutId = payload;
  },
  [SET_POPUP_MEDIA_CONTENT](state, payload) {
    state.popupMediaContent = payload;
  },
};

export const actions = {};
