import { fireLinkTag } from './tagManager';

export function tagAccountLink() {
  fireLinkTag({
    link_name: 'hdrnav-accounts',
    cm_sp: 'navigation-_-top_nav-_-sign-in',
    lid: 'glbtopnav_sign_in-us',
  });
}

export function tagRegistryLink() {
  fireLinkTag({
    link_name: 'hdrnav-registry',
    cm_sp: 'navigation-_-top_nav-_-gift-registry',
  });
}

export function tagStoresLink() {
  fireLinkTag({ link_name: 'hdrnav-stores' });
}

export default {
  tagAccountLink,
  tagRegistryLink,
  tagStoresLink,
};
