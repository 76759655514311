import { computed } from 'vue';
import { useStore } from 'vuex';
import $http from '@vue-util/http';
import { SET_MODAL_OVERLAY_VISIBILITY } from '@atomic-ui/modalOverlay';

import { NAV_SHARED_STORE } from '../../types/names';
import { SET_POPUP_MEDIA_CONTENT } from '../../types/mutations';

export const usePopupLink = () => {
  const store = useStore();
  const popupMediaContent = computed(() => store.state[NAV_SHARED_STORE].popupMediaContent);
  const { hostlink } = store.state.envProps;
  const host = process.env.NODE_ENV === 'development' ? '' : hostlink;

  // for AEM popup
  const popupLinkHandler = (e) => {
    const elem = e.target?.closest('a[href*="popupOverlayId"]');
    if (elem) {
      e.preventDefault();
      const popupId = elem.href?.match(/popupOverlayId=(\d+)/)[1];
      const reqUrl = `${host}/shop/mediapopup?queryType=popup&popupID=${popupId}&isAEM=true&xapi=1`;

      $http.get(reqUrl)
        .then((response) => {
          store.commit(
            `${NAV_SHARED_STORE}/${SET_POPUP_MEDIA_CONTENT}`,
            response.data.mediacontent,
          );
          store.commit(
            `modalOverlayStore/${SET_MODAL_OVERLAY_VISIBILITY}`,
            'popUpOverlay',
          );
        })
        .catch(() => {});
    }

    return true;
  };

  return {
    popupMediaContent,
    popupLinkHandler,
  };
};

export default {};
