<template>
  <div
    v-if="copyright"
    class="text-center"
  >
    <!-- eslint-disable vue/no-v-html -->
    <p
      class="small"
      v-html="copyright"
    />
    <!-- eslint-enable -->
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_COPYRIGHT } from '../../types/getters';

const store = useStore();
const copyright = computed(() => store.getters[`footerStore/${GET_COPYRIGHT}`]);
</script>

<style lang="scss" scoped brand="mcom">
p, :deep(a) {
  color: $white;
}
</style>

<style lang="scss" scoped brand="bcom">
@import '../../scss/mixins';

:deep(a) {
  @include body-menu-link-behavior;
}
</style>
