/* istanbul ignore file */
import { useStore } from 'vuex';
import { computed } from 'vue';

import { NAV_EXPERMENTATION_STORE } from '../types/names';
import { GET_EXPERIMENTS_IDS } from '../features/experimentation/types';

export default function useExperimentsIds(_store) {
  const store = _store || useStore();

  return computed(() => store.getters[`${NAV_EXPERMENTATION_STORE}/${GET_EXPERIMENTS_IDS}`]);
}
