import { computed } from 'vue';
import { useStore } from 'vuex';
import { watchOnce } from '@vueuse/core';

export default function onUserInit(cb) {
  const store = useStore();
  const initialized = computed(() => store.state.navUser.initialized);

  if (initialized.value) {
    cb();
    return;
  }

  watchOnce(initialized, cb);
}
