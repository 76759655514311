<template>
  <LoyaltyPoints />
  <li
    v-for="item in myAccountLinks"
    :key="item.text"
    class="margin-top-xs padding-left-m padding-right-m list-item"
  >
    <Link
      class="menu-link-body"
      :href="item.url"
      :tracking="item.tracking"
      @click="onLinkClick(item)"
    >
      {{ item.text }}
    </Link>
  </li>
</template>

<script setup>
/* istanbul ignore file */
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { GET_MYACCOUNT_LINKS } from '../../types/getters';
import Link from '../common/Link';
import { ExpiresStorageProxy } from '../../utils/storage/expireStorageProxy';
import { fireLinkTag } from '../../features/analytics/tagManager';
import useSignInClick from '../../composables/useSignInClick';
import useSignOutClick from '../../composables/useSignOutClick';

/**
 * @typedef AccountLink
 * @type {import('../../constants/common/accountLinks.js').AccountLink}
 */

const store = useStore();
const { isMcom } = store.state.envProps;
const LoyaltyPoints = defineAsyncComponent(() => import(/* webpackChunkName: "LoyaltyPointsDesktop" */ './LoyaltyPoints'));

/**
 * @type {import('vue').ComputedRef<AccountLink[]>}
 */
const myAccountLinks = computed(() => store.getters[`headerStore/${GET_MYACCOUNT_LINKS}`]);
/**
 * @param {MouseEvent} event
 * @param {AccountLink} item
 */
const onLinkClick = async (item) => {
  if (item.burstCache) {
    ExpiresStorageProxy(localStorage).clear();
  }

  if (item.isSignIn) {
    useSignInClick();
  }

  if (item.isSignOut) {
    useSignOutClick(isMcom);
  }

  if (item.text === 'My Account') {
    const myAccountPayload = {
      link_name: 'glbtopnav_my_account-us',
      page_name: 'home',
      page_type: 'my account',
      cm_sp: 'navigation-_-top_nav-_-my_account',
      lid: 'glbtopnav_my_account-us',
    };
    fireLinkTag(myAccountPayload);
  }
  if (item.text === "Macy's Credit Card") {
    const creditCardPayload = {
      link_name: 'glbtopnav_macys_credit_card-us',
      page_name: 'credit card',
      page_type: 'my account',
      cm_sp: 'navigation-_-top_nav-_-macy%27s_credit_card',
      lid: 'glbtopnav_macys_credit_card-us',
    };
    fireLinkTag(creditCardPayload);
  }
  if (item.text.includes('Purchases')) {
    const purchasePayload = {
      link_name: 'glbtopnav_purchases__returns-us',
      page_name: 'order lookup',
      page_type: 'my account',
      cm_sp: 'navigation-_-top_nav-_-purchases_%26_returns',
      lid: 'glbtopnav_purchases__returns-us',
    };
    fireLinkTag(purchasePayload);
  }
  if (item.text === 'Profile') {
    const profilePayload = {
      link_name: 'glbtopnav_profile-us',
      page_name: 'profile',
      page_type: 'my account',
      cm_sp: 'navigation-_-top_nav-_-profile',
      lid: 'glbtopnav_profile-us',
    };
    fireLinkTag(profilePayload);
  }
  if (item.text === 'Wallet') {
    const walletPayload = {
      link_name: 'glbtopnav_wallet-us',
      page_name: 'my account wallet',
      page_type: 'wallet',
      cm_sp: 'navigation-_-top_nav-_-wallet',
      lid: 'glbtopnav_wallet-us',
    };
    fireLinkTag(walletPayload);
  }
  if (item.text === 'Lists') {
    const listsPayload = {
      link_name: 'glbtopnav_lists-us',
      page_name: 'wishlist home',
      page_type: 'wishlist',
      cm_sp: 'navigation-_-top_nav-_-lists',
      lid: 'glbtopnav_lists-us',
    };
    fireLinkTag(listsPayload);
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/mixins';
.list-item {
  &:nth-child(2) {
    @include padding-direction('top', map-get($spacer, 's'));
    @include margin-top-z;
  }
  &:last-of-type {
    @include padding-direction('bottom', map-get($spacer, 's'));
  }
}
</style>
<style lang="scss" scoped brand="mcom">
.profile {
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.menu-link-body {
  font-size: 14px; // since it is nested in a button it gets reset
}
</style>

<style lang="scss" scoped brand="bcom">
</style>
