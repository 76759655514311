export const LEADING_LENGTH = 14;
export const EXPIRING_PREFIX = '_expires_';

/**
 * Serializes a value and adds a timestamp.
 * @param {string} value
 * @param {number} expireAt
 * @returns
 */
const serialize = (value, expireAt) => `${expireAt.toString().padStart(LEADING_LENGTH, '0')}${value}`;

/**
 * @param {string} rawValue
 */
const deserialize = (rawValue) => ({
  value: rawValue.slice(LEADING_LENGTH),
  expireAt: parseInt(rawValue.slice(0, LEADING_LENGTH), 10),
});

/**
 * ExpiresStorageProxy
 * Returns a storage proxy that expires after a specified amount of time.
 *
 * @param {import("@vueuse/core").StorageLike} storage storage object
 * @param {object} options options
 * @param {number} [options.expiresIn] expiration in milliseconds
 * @param {Window} [options.$window] window object (for testing)
 * @returns {import("@vueuse/core").StorageLike}
 */
export const ExpiresStorageProxy = (storage, {
  expiresIn = 1000 * 60 * 60 * 24,
  $window = window,
} = {}) => {
  const getIsPrefixed = (key) => key.startsWith(EXPIRING_PREFIX);
  const cleanUp = () => Object
    .entries(storage)
    .filter(([key]) => getIsPrefixed(key))
    .filter(([, rawItem]) => deserialize(rawItem).expireAt < Date.now())
    .forEach(([key]) => storage.removeItem(key));

  $window.addEventListener('beforeunload', cleanUp);

  return new Proxy(storage, {
    get(target, prop) {
      if (prop === 'getItem') {
        return (key) => {
          const fullKey = EXPIRING_PREFIX + key;
          const rawItem = target.getItem(fullKey);
          if (rawItem) {
            const { value, expireAt } = deserialize(rawItem);
            if (expireAt > Date.now()) {
              return value;
            }
            target.removeItem(fullKey);
          }
          return null;
        };
      }
      if (prop === 'setItem') {
        return (key, value) => {
          target.setItem(EXPIRING_PREFIX + key, serialize(value, Date.now() + expiresIn));
        };
      }
      if (prop === 'removeItem') {
        return (key) => {
          storage.removeItem(EXPIRING_PREFIX + key);
        };
      }
      if (prop === 'clear') {
        return () => {
          Object
            .entries(target)
            .filter(([key]) => getIsPrefixed(key))
            .forEach(([key]) => storage.removeItem(key));
        };
      }
      return target[prop];
    },
    // Setters are not supported
    set() {
      throw new Error('Operation is not permitted. Setters are not supported.');
    },
    // Show the keys with the prefix
    ownKeys(target) {
      return Object.keys(target).filter(getIsPrefixed);
    },
    // Show the keys with the prefix
    has(target, prop) {
      return prop.startsWith(EXPIRING_PREFIX) && target.has(prop);
    },
    // Show the keys with the prefix
    getOwnPropertyDescriptor(target, prop) {
      if (prop.startsWith(EXPIRING_PREFIX)) {
        return Object.getOwnPropertyDescriptor(target, prop);
      }
      return undefined;
    },
  });
};

export default ExpiresStorageProxy;
