import { onMounted, onUnmounted } from 'vue';

/**
 * Custom composable to schedule a callback when the browser is idle and has sufficient idle time available.
 * Utilizes `requestIdleCallback` if supported, with a fallback to `setTimeout`.
 *
 * @param {Function} cb - The callback function to execute during idle time.
 * @param {Object} options - Configuration options for the idle callback.
 * @param {number} options.timeout - The maximum time in milliseconds to wait before forcing the callback. Default is `1000`.
 * @param {number} options.availableIdleTime - The minimum idle time in milliseconds required to execute the task. Default is `0`. Maximum is `50`.
 *
 * @example
 * import onBrowserIdle from './onBrowserIdle';
 *
 * export default {
 *   setup() {
 *     onBrowserIdle(() => {
 *       // run complex task here
 *     }, { timeout: 2000, availableIdleTime: 15 });
 *   },
 * };
 */
export default function onBrowserIdle(cb, options = { timeout: 1000, availableIdleTime: 0 }) {
  const MAX_IDLE_TIME = 50;
  let idleCallbackId;
  let timeoutId;

  /**
   * Unsubscribes from the currently scheduled idle callback.
   */
  const unsubscribe = () => {
    if ('cancelIdleCallback' in window) {
      cancelIdleCallback(idleCallbackId);
    }
    clearTimeout(timeoutId);
    idleCallbackId = null;
  };

  /**
   * Schedules a new idle callback.
   */
  const scheduleIdleCallback = () => {
    idleCallbackId = requestIdleCallback(handleIdle, options); // eslint-disable-line no-use-before-define
  };

  /**
   * Handles the idle period.
   * Executes the callback if sufficient idle time is available; otherwise, reschedules the callback.
   *
   * @param {IdleDeadline} deadline - The object representing the current idle period.
   */
  const handleIdle = (deadline) => {
    const timeRemaining = deadline.timeRemaining();

    if (timeRemaining === MAX_IDLE_TIME || timeRemaining > options.availableIdleTime) {
      cb();
      unsubscribe();
    } else {
      scheduleIdleCallback();
    }
  };

  onMounted(() => {
    if ('requestIdleCallback' in window) {
      scheduleIdleCallback();
    }

    timeoutId = setTimeout(() => handleIdle({ timeRemaining: () => MAX_IDLE_TIME }), options.timeout);
  });

  onUnmounted(() => {
    if (idleCallbackId) {
      unsubscribe();
    }
  });
}
