import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import useScrollLock from './ui/useScrollLock';
import { onMenuOpen, onMenuClose } from '../features/analytics/mobileMenuTags';
import useProvideFactory from './dumb/useProvideFactory';
import { MOBILE_NAVIGATION_OVERLAY } from '../constants/common/overlayNames';
import {
  RESET_OVERLAY,
  SET_ACTIVE_OVERLAY_NAME,
} from '../types/mutations';

export const MOBILE_NAV_REPOSITORY = Symbol('MOBILE_NAV_REPOSITORY');

export function getMobileNavSharedState() {
  const mode = ref('root');
  const eventClass = ref('');
  const stack = ref([]);
  const poss = ref([]);
  const isMenuOpened = ref(false);
  const isMenuHydrated = ref(false);
  const wasSideNavVisibleRecently = ref(false);
  const menuTarget = ref(null);

  return {
    mode, eventClass, stack, poss, isMenuOpened, isMenuHydrated, wasSideNavVisibleRecently, menuTarget,
  };
}

export function useMobileNavProvide(useAsInject) {
  return useProvideFactory({
    key: MOBILE_NAV_REPOSITORY,
    factory: getMobileNavSharedState,
    useAsInject,
  });
}

export function useMobileNav() {
  const store = useStore();
  const {
    mode, eventClass, stack, poss, isMenuOpened, isMenuHydrated, wasSideNavVisibleRecently, menuTarget,
  } = useMobileNavProvide(true);
  const { lock, unlock } = useScrollLock();

  function getLinkPosition() {
    return poss.value[poss.value.length - 1];
  }

  function lastStackItem() {
    return stack.value[stack.value.length - 1];
  }

  function back() {
    eventClass.value = 'collapse';
    stack.value.pop();
    poss.value.pop();

    if (!stack.value.length) {
      mode.value = 'root';
    }
  }

  function reset() {
    stack.value = [];
    poss.value = [];
    mode.value = 'root';
    eventClass.value = '';
  }

  function setMode({
    categoryId, analyticsCategoryId, id, pos, title, url,
  }, isSub) {
    const newId = categoryId || id;
    eventClass.value = 'expand';
    mode.value = newId;

    if (isSub) {
      stack.value.push({
        title,
        url,
        id: newId,
        analyticsCategoryId,
      });
      poss.value.push(pos);
    }
  }

  function stackLen() {
    return stack.value.length;
  }

  function openNav() {
    lock();
    isMenuOpened.value = true;
    wasSideNavVisibleRecently.value = true;
    onMenuOpen();
    store.commit(`navOverlay/${SET_ACTIVE_OVERLAY_NAME}`, MOBILE_NAVIGATION_OVERLAY);
  }

  function closeNav() {
    unlock();
    isMenuOpened.value = false;
    onMenuClose();
    reset();
    store.commit(`navOverlay/${RESET_OVERLAY}`);
    menuTarget.value?.focus();

    // note: this setTimeout() helps us keep track if certain actions were
    // perform while the side nav was open. The short 1sec delay is just
    // a window to check for that validation.
    setTimeout(() => {
      wasSideNavVisibleRecently.value = false;
    }, 1000);
  }

  function toggleNav() {
    if (isMenuOpened.value) {
      closeNav();
    } else {
      menuTarget.value?.blur();
      openNav();
    }
  }

  const isRootMode = computed(() => mode.value === 'root');
  const isAccountMode = computed(() => mode.value === 'SITE_MYACCOUNT_MENU');
  const isStoreMode = computed(() => mode.value === 'store');
  const getLevelsData = computed(() => {
    const levels = stack.value.reduce((acc, cur, index) => {
      const level = (index === 0) ? 'topCategoryName' : `l${index}`;
      acc[level] = cur.title;
      return acc;
    }, {});

    return levels;
  });

  return {
    setMode,
    lastStackItem,
    isRootMode,
    isAccountMode,
    isStoreMode,
    eventClass,
    stack,
    isMenuOpened,
    isMenuHydrated,
    wasSideNavVisibleRecently,
    menuTarget,
    back,
    reset,
    openNav,
    closeNav,
    toggleNav,
    analytics: {
      getLevelsData,
      getLinkPosition,
      getCol: stackLen,
      getCategoryId: () => lastStackItem()?.analyticsCategoryId,
    },
  };
}

export default {};
