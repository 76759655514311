/* eslint-disable no-shadow */
import { combineKillswitches, getClientSideOnlyKillswitches } from '../../features/killswitches';
import { UPDATE_KILLSWITCHES } from '../../features/killswitches/types/mutations';
import { LOAD_KILLSWITCHES } from '../../features/killswitches/types/actions';
import { useKillSwitchesFetch } from '../../composables/dumb/useNavigationFetch';
import { getKillswitchConfig } from '../headerStore/helpers';

export function state() {
  return {
    killswitches: {},
    isFlags: false,
    isProperties: false,
  };
}

export const getters = {};

let loadingPromise;

// for testing purposes only
export function resetLoadingPromise() {
  loadingPromise = null;
}

export const mutations = {
  [UPDATE_KILLSWITCHES](state, killswitches) {
    state.killswitches = { ...state.killswitches, ...killswitches };
  },
  setFlagsReady(state) {
    state.isFlags = true;
  },
  setPropertiesReady(state) {
    state.isProperties = true;
  },
};

export const actions = {
  [LOAD_KILLSWITCHES]({ commit, rootState }, { killswitches, localKillswitches }) {
    const result = combineKillswitches({ killswitches, localKillswitches, killswitchesOverride: rootState.pageData.killswitches?.override });
    commit(UPDATE_KILLSWITCHES, result);
    commit('setFlagsReady');
    commit('setPropertiesReady');
  },
  applyKillswitches({ commit }, killswitches) {
    commit(UPDATE_KILLSWITCHES, killswitches);
    commit('setFlagsReady');
    commit('setPropertiesReady');
  },
  async fetch({ dispatch, rootState }) {
    if (loadingPromise) {
      return loadingPromise;
    }

    const { hostlink: clientBaseUrl, headerFooterXapiHost: serverBaseUrl, isNavSSR } = rootState.envProps;
    const { fetch, result } = useKillSwitchesFetch({
      clientBaseUrl, serverBaseUrl,
    });
    let resolve;

    loadingPromise = new Promise((_resolve) => {
      resolve = _resolve;
    });

    await fetch();

    const isFetchSucceed = result.value && typeof result.value === 'object';

    if (isFetchSucceed) {
      const killswitches = isNavSSR ? getClientSideOnlyKillswitches(result.value) : result.value;
      await dispatch(LOAD_KILLSWITCHES, getKillswitchConfig({ meta: { properties: killswitches } }));
      rootState.envProps.xMacysApiKey = killswitches.xMacysApiKey;
      rootState.envProps.hmacKey = killswitches.hmacKey;
    } else {
      loadingPromise = null;
    }

    resolve();

    return loadingPromise;
  },
};
