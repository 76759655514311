const Links = require('../../constants/Links');
const { localKillswitches } = require('../../features/killswitches/config');
const AccountLinks = require('../../constants/AccountLinks');

function addSignedInState(data, rootState) {
  const { isSignedIn } = rootState.navUser.data;

  if (isSignedIn) {
    const signOutLink = {
      text: AccountLinks.SIGNOUT_TEXT, burstCache: true, isSignOut: true, ...Links.account.signOut,
    };

    return [...data, signOutLink];
  }

  const signInLink = {
    text: AccountLinks.SIGNIN_TEXT, burstCache: true, isSignIn: true, ...Links.account.signIn,
  };

  return [signInLink, ...data];
}

function getKillswitchConfig(xapiResponse) {
  return { killswitches: xapiResponse.meta.properties, localKillswitches };
}

module.exports = { addSignedInState, getKillswitchConfig };
