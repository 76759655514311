import { useAttrs } from 'vue';

import overridesMap from '../../constants/overridesMap';
import kebabToCamel from '../../utils/kebabToCamel';
import { useQueryParams } from './useQueryParams';

function isBoolean(value) {
  return typeof value === 'boolean' || ['', 'true', 'false'].includes(value);
}

function isTrue(value) {
  if (typeof value === 'boolean') {
    return value;
  }

  return value === '' || value === 'true';
}

/* eslint-disable import/prefer-default-export */
export function useAttrsOverrides() {
  const attrs = useAttrs();
  const query = useQueryParams();
  const normalizedAttrs = Object.entries(attrs).reduce((acc, [key, value]) => {
    const keyFormatted = kebabToCamel(key);

    return {
      ...acc,
      [keyFormatted]: isTrue(value),
    };
  }, {});
  const overrides = Object.entries(query).reduce((acc, [key, value]) => {
    if (!isBoolean(value)) return acc;
    if (!overridesMap[key]) return acc;

    const keyFormatted = key.replace('_', '');

    return { ...acc, [keyFormatted]: isTrue(value) };
  }, {});

  return {
    ...normalizedAttrs,
    ...overrides,
  };
}
