import { computed } from 'vue';
import { useStore } from 'vuex';

import { NAV_SHARED_STORE } from '../../types/names';
import { SET_SHOW_INTERNATIONAL_CONTEXT_FORM, SET_SLIDEOUT_ID } from '../../types/mutations';

export default function useInternationalSlideout() {
  const store = useStore();

  const showForm = computed(() => store.state[NAV_SHARED_STORE].showInternationalContextForm);
  const slideoutId = computed(() => store.state[NAV_SHARED_STORE].slideoutId);

  function init(id) {
    store.commit(`${NAV_SHARED_STORE}/${SET_SLIDEOUT_ID}`, id);
  }

  function toggleSlideout() {
    store.commit('slideoutStore/TOGGLE', slideoutId.value);
  }

  function openInternationalSlideout() {
    store.commit(`${NAV_SHARED_STORE}/${SET_SHOW_INTERNATIONAL_CONTEXT_FORM}`, true);
    toggleSlideout();
  }

  function onSlideoutClose() {
    store.commit(`${NAV_SHARED_STORE}/${SET_SHOW_INTERNATIONAL_CONTEXT_FORM}`, false);
  }

  return {
    showForm,
    slideoutId,
    init,
    toggleSlideout,
    openInternationalSlideout,
    onSlideoutClose,
  };
}
