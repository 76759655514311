<template>
  <form
    class="wrapper grid-y"
    :class="{ 'wrapper-redesigned': enhancedMobileNavFeature }"
    :action="formAction"
    method="GET"
    @submit.prevent=""
  >
    <Search
      ref="searchComponent"
      v-model="searchValue"
      name="keyword"
      class="search-wrapper"
      :class="{ 'animate': isSearchBarAnimated }"
      @onEnter="handleSubmit"
      @onClear="handleOnClear"
      @close="handleOnClear"
    />
    <button
      type="button"
      class="close close-large"
      aria-label="Close search"
      @click="emit('close')"
    />
  </form>
</template>

<script setup>
/* istanbul ignore file */
import {
  computed, watch, ref, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import { useDebounceFn } from '@vueuse/core';
import Search from '@atomic-ui/search';
import useSearchHistory from '../../composables/dumb/useSearchHistory';
import useFeatureEligibility from '../../composables/useFeatureEligibility';

import { FETCH_SEARCH_SUGGESTIONS } from '../../types/actions';
import { SET_SEARCH_VALUE } from '../../types/mutations';
import { securityValidation } from '../../utils/StringUtils';
import getSearchPrefix from '../../utils/getSearchPrefix';
import {
  tagTypedSearch, tagFromHamburgerSearchBar, tagSearchWhileHamburgerOpen,
} from '../../features/analytics/searchTags';
import { normalizeSearchString } from '../../utils/normalizeSearchString';
import { SEARCH_PAGE_PREFIXES } from '../../constants';
import addQueryParam from '../../utils/addQueryParam';
import { GET_IS_MOBILE_VIEW } from '../../types/getters';
import { useMobileNav } from '../../composables/useMobileNav';

const emit = defineEmits(['close']); // eslint-disable-line no-undef

const store = useStore();
const searchComponent = ref(null);
const searchIsActivedWhileMobileMenuWasOpen = ref(false);

const { wasSideNavVisibleRecently } = useMobileNav();
const { enhancedMobileNavFeature } = useFeatureEligibility();
const { isMcom, hostlink } = store.state.envProps;
const { addToSearchHistory } = useSearchHistory();

const formAction = `${hostlink}/shop/search`;
const isMobile = computed(() => store.getters[`navViewTypeStore/${GET_IS_MOBILE_VIEW}`]);
const isSearchBarAnimated = computed(() => store.state.headerStore.isSearchBarAnimated);
const isSearchModalActive = computed(() => store.state.headerStore.isSearchModalActive);
const searchValue = computed({
  get() {
    return store.state.headerStore.searchValue;
  },
  set(value) {
    store.commit(`headerStore/${SET_SEARCH_VALUE}`, value);
  },
});

// TODO (Refactor) move to SearchSuggestions component
const fetchSearchSuggestions = useDebounceFn((value) => {
  store.dispatch(`navSearchSuggestionsStore/${FETCH_SEARCH_SUGGESTIONS}`, value.trim());
}, 300);

function handleSubmit(value) {
  if (securityValidation(value)) {
    const searchPrefix = getSearchPrefix(store);
    const normalizedSearchValue = normalizeSearchString(value, searchPrefix === SEARCH_PAGE_PREFIXES.featured);

    if (isMcom) tagTypedSearch(value);

    if (isMcom && isMobile.value && searchIsActivedWhileMobileMenuWasOpen.value) {
      if (enhancedMobileNavFeature.value) {
        tagFromHamburgerSearchBar();
      } else {
        tagSearchWhileHamburgerOpen();
      }
    }

    addToSearchHistory(value);
    window.location.href = addQueryParam(`${hostlink}${searchPrefix}${normalizedSearchValue}`, { ss: true });
  }
}

function handleOnClear() {
  const inputElement = searchComponent.value.$el.querySelector('.input-search');
  if (inputElement) {
    inputElement.focus();
  }
}

watch(searchValue, fetchSearchSuggestions, { immediate: true });
watch(isSearchModalActive, (value) => {
  if (value) {
    nextTick(() => {
      const searchInput = document.querySelector('.search-modal .input-search');
      if (searchInput) {
        searchInput.focus();
      }
    });

    // tracking if user open search while mobile menu was open
    searchIsActivedWhileMobileMenuWasOpen.value = wasSideNavVisibleRecently.value;
  }
});
</script>

<style scoped lang="scss">
@import '../../scss/mixins/index.scss';
.wrapper {
  width: 100%;
  max-width: none;
  position: relative;
  top: 0;
  padding-right: 20px;
}

@include breakpoint(large up) {
  .wrapper {
    @include padding-right-z;
  }
}

.close {
  position: absolute;
  cursor: pointer;
}

:deep(.input-container) {
  margin: 0;
}

.wrapper-redesigned {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-right: 24px;

  .close {
    right: 24px;
    top: 7px;
  }

  .search-wrapper {
    margin-right: 42px;
    width: 100%;

    @keyframes expandSearchBar {
      0% { width: 114px; }
      to { width: 100%; }
    }

    &.animate {
      animation-name: expandSearchBar;
      animation-duration: .3s;
      animation-fill-mode: both;
    }
  }
}
</style>

<style scoped lang="scss" brand="mcom">
.close {
  right: 20px;
  top: -58px;
}

@include breakpoint(large up) {
  .close {
    right: -48px;
    top: 8px;
  }
}
</style>

<style scoped lang="scss" brand="bcom">
.close {
  right: 20px;
  top: -56px;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .wrapper {
    right: -120px;
  }
}

@include breakpoint(large up) {
  .close {
    right: -48px;
    top: 8px;
  }
}
</style>
