<template>
  <LinkRailMenuItem
    :with-chevron="isMcom"
    :position-x="positionX"
  >
    <template #default>
      <Link
        ref="$activator"
        class="link-rail-item small undecorated-link"
        href="/registry"
        @click="handleActivatorClick"
      >
        <slot />
      </Link>
    </template>

    <template #content>
      <section class="hnf-registry-dropdown">
        <article
          v-if="hasRegestries || showSignIn"
          class="hnf-registry-manage"
        >
          <div
            class="medium margin-bottom-xs"
          >
            Manage your registry
          </div>
          <div
            v-if="showSignIn"
            class="link-item"
          >
            <Link
              class="link-body"
              :href="defaultLinks.signinUrl"
              @click="setSignInRedirectParams"
            >
              Sign in
            </Link>
          </div>
          <div
            v-for="(occasion, index) of occasions"
            :key="occasion.registryId"
            class="link-item margin-top-xs"
            :class="{'margin-top-xs' : index !== 0}"
          >
            <Link
              class="link-body"
              :href="'/registry/' + occasion.registryId"
              @click.prevent="setDefaultRegistry(occasion.registryGUID)"
            >
              {{ occasion.text }}
            </Link>
          </div>
        </article>

        <article class="hnf-registry-groups">
          <div
            v-for="(group, groupIndex) of linkGroups"
            :key="groupIndex"
            class="hnf-registry-group"
          >
            <div
              v-if="group.title"
              class="medium margin-bottom-xs"
            >
              {{ group.title }}
            </div>
            <div
              v-for="(link, linkIndex) of group.items"
              :key="link.text"
              class="link-item"
              :class="{'margin-top-xs' : linkIndex !== 0}"
            >
              <Link
                class="link-body"
                :href="link.url"
              >
                {{ link.text }}
              </Link>
            </div>
          </div>
        </article>
      </section>
    </template>
  </LinkRailMenuItem>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import LinkRailMenuItem from './LinkRailMenuItem.common';
import Link from '../common/Link';
import { useRegistry, useRegistryDropdown } from '../../composables/useRegistry';
import { useMobileNav } from '../../composables/useMobileNav';

const store = useStore();
const { isMcom } = store.state.envProps;
const $activator = ref(null);
const positionX = computed(() => (isMcom ? 'right' : 'left'));

const {
  hasRegestries, showSignIn, occasions, linkGroups,
} = useRegistryDropdown();
const { defaultLinks, setDefaultRegistry, setSignInRedirectParams } = useRegistry();
const { isMenuOpened } = useMobileNav();

function handleActivatorClick(e) {
  if (isMenuOpened.value) {
    e.preventDefault();
    setTimeout(() => {
      $activator.value.$el.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
    });
  }
}
</script>

<style lang="scss">
@import '../../scss/mixins/index.scss';

.hnf-registry-dropdown {
  width: 250px;
  article {
    @include padding(map-get($spacer, 's'), map-get($spacer, 'm'), map-get($spacer, 's'), map-get($spacer, 'm'));
  }
}

.hnf-registry-manage {
  border-bottom: 1px solid $gray-4-color;
}

.hnf-registry-groups {
  background-color: $background-color;
}

.hnf-registry-group + .hnf-registry-group {
  border-top: $hr-gray-divider;
  @include margin-top-s;
  @include padding-top-s;
}
</style>
