import { tryOnMounted } from '@vueuse/shared';
import { ref, watch } from 'vue';
import {
  useEventListener, useMediaQuery,
} from '@vueuse/core';

import { isSSR } from '../../utils/isSSR';
import useProvideFactory from './useProvideFactory';

function useWindowSize(options = {}) {
  const {
    _window = isSSR() ? undefined : window,
    initialWidth = Infinity,
    initialHeight = Infinity,
    listenOrientation = true,
    includeScrollbar = true,
  } = options;
  const width = ref(initialWidth);
  const height = ref(initialHeight);
  const update = () => {
    if (_window) {
      if (includeScrollbar) {
        width.value = _window.innerWidth;
        height.value = _window.innerHeight;
      } else {
        width.value = _window.document.documentElement.clientWidth;
        height.value = _window.document.documentElement.clientHeight;
      }
    }
  };
  update();
  tryOnMounted(update);
  useEventListener('resize', update, { passive: true });
  if (listenOrientation) {
    const matches = useMediaQuery('(orientation: portrait)');
    watch(matches, () => update());
  }
  return { width, height };
}

export const WINDOW_RESIZE_REPOSITORY = Symbol('WINDOW_RESIZE_REPOSITORY');

export function getWindowResizeSharedState() {
  const { width, height } = useWindowSize({ includeScrollbar: true });
  return { width, height };
}

export function useWindowResizeProvide(useAsInject) {
  return useProvideFactory({
    key: WINDOW_RESIZE_REPOSITORY,
    factory: getWindowResizeSharedState,
    useAsInject,
  });
}

export function useWindowResize() { //eslint-disable-line
  const { width, height } = useWindowResizeProvide(true);
  // TODO do we want to debounce the width/height before updating return?
  return { height, width };
}
