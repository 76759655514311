import { fireLinkTag } from './tagManager';

export default function signInTooltipTags() {
  const eventName = 'tool_tip_sign_in';
  const ctaName = 'nav sign in click';
  const eventData = `${eventName}:${ctaName}`;
  const payload = {
    event_name: eventData,
    link_name: eventData,
  };

  fireLinkTag(payload);
}
