<template>
  <div
    ref="$overlay"
    class="overlay"
    :class="[overlayName, {
      'shown': isActive,
      'overlay--enhanced-m': enhancedMobileNavFeature,
      'disable-media-rail': experiments.disableMediaRail,
    }]"
    :style="{'margin-top': offset, height: overlayHeight}"
    @click="handleClick"
  />
</template>

<script setup>
/* istanbul ignore file */
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useEventBus } from '@vueuse/core';
import {
  GET_IS_ACTIVE_OVERLAY, GET_OVERLAY_NAME, GET_ATTRS, GET_DEALS_DATA,
} from '../../types/getters';
import { OVERLAY, events } from '../../constants/eventBus';
import { useDocumentHeight } from '../../composables/dumb/useDocumentHeight';
import useFeatureEligibility from '../../composables/useFeatureEligibility';
import useKillswitches from '../../composables/useKillswitches';
import { MOBILE_NAVIGATION_OVERLAY } from '../../constants/common/overlayNames';
import useExperiments from '../../composables/useExperiments';

const store = useStore();
const $overlay = ref(null);
const experiments = useExperiments();

const { isMinimal } = store.getters[`headerStore/${GET_ATTRS}`];
const { enhancedMobileNavFeature } = useFeatureEligibility();
const killswitches = useKillswitches();
const isDealsEnabled = computed(() => !!killswitches.value.vueDealsEnabled && store.getters[`headerStore/${GET_ATTRS}`].isDeals && !!store.getters[`headerStore/${GET_DEALS_DATA}`]);
const isActive = computed(() => store.getters[`navOverlay/${GET_IS_ACTIVE_OVERLAY}`]);
const overlayName = computed(() => store.getters[`navOverlay/${GET_OVERLAY_NAME}`]);
const documentHeight = useDocumentHeight();
const offset = computed(() => {
  const isMenuOpened = overlayName.value === MOBILE_NAVIGATION_OVERLAY;
  const { skipToContentOffset } = store.state.headerStore;
  const dealsOffset = isDealsEnabled.value && !isMenuOpened ? 40 : 0;
  const result = skipToContentOffset + dealsOffset;

  return `${result}px`;
});
const overlayHeight = computed(() => {
  if (!isActive.value || !documentHeight.value) return 0;

  const offsetTop = $overlay.value?.getBoundingClientRect().top || 0;
  const baseHeight = documentHeight.value - store.state.headerStore.skipToContentOffset - offsetTop;
  const minimalFooterHeight = 82;
  const height = isMinimal ? baseHeight + minimalFooterHeight : baseHeight;

  return `${height}px`;
});

const bus = useEventBus(OVERLAY);
function handleClick() {
  bus.emit(events[OVERLAY].CLICK);
}
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $overlay-color;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear 50ms;

  &.shown {
    visibility: visible;
    opacity: 1;
  }

  &.search-modal {
    top: 0;
    background-color: $white;
    transition: none;
  }

  @include breakpoint(large up) {
    &.search-modal {
      background-color: $overlay-color;
      transition: opacity 0.25s linear 50ms;
    }
  }
}
</style>

<style lang="scss" brand="mcom" scoped>
.overlay {
  &.link-rail {
    top: 43px;
  }

  &.quick-bag {
    top: 112px;
    z-index: 1;
  }

  &.navigation {
    top: 180px;
    z-index: 1;
  }
}

@include breakpoint(small) {
  .overlay {
    &.overlay--enhanced-m {
      &.mobile-navigation {
        top: 0;
      }

      &.search-modal {
        z-index: 22;
      }
    }
  }
}
</style>

<style lang="scss" brand="bcom" scoped>
.overlay {
  &.link-rail {
    top: 80px;
  }

  &.link-rail.disable-media-rail {
    top: 40px;
  }

  &.search-modal {
    top: 160px;
  }
}

@include breakpoint(medium down) {
  .overlay {
    &.mobile-navigation {
      top: 0;
    }
  }
}
</style>
