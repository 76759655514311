<template>
  <div class="grid-x">
    <div
      class="cell small-12 small-offset-0 offset-1 large-offset-1 large-10 xlarge-offset-2 xlarge-8"
    >
      <ul ref="$list">
        <li
          v-for="item in items"
          :key="item.name"
          class="margin-bottom-xs"
          :class="{ 'no-separator': !item.separator }"
        >
          <Link
            class="menu-link-sm font-small"
            :is-open-new-tab="item.isOpenNewTab"
            :href="item.url"
            :tracking="item.tracking"
            :data-one-trust="item.oneTrust ? true : undefined"
            @click="handleLinkClick($event, item)"
          >
            <span>{{ item.name }}</span>
          </Link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
/* istanbul ignore file */
import { ref, computed, toRefs } from 'vue';
import Link from '../common/Link';
import useListSeparators from '../../composables/dumb/useListSeparators';

const props = defineProps({ // eslint-disable-line no-undef
  links: {
    type: Array,
    required: true,
  },
});

const { links } = toRefs(props);
const $list = ref(null);
const { separators } = useListSeparators($list);
const items = computed(() => links.value.map((item, index) => ({
  ...item,
  separator: separators.value[index],
})));

/**
 * Handles link click event
 *
 * @description
 * Access to window object is required but is not available directly in template
 */
const handleLinkClick = (e, item) => {
  if (item.oneTrust) {
    e.preventDefault();
    window.OneTrust?.ToggleInfoDisplay();
  }
};
</script>

<style lang="scss" scoped>
$list-margin-correction: -12px;

ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: $list-margin-correction;
  margin-right: $list-margin-correction;

  li {
    border-left: $gray-2-border;
    display: flex;
    align-items: center;
    padding: 0 12px;

    @include breakpoint(large up) {
      padding: 0 16px;
    }

    &:first-child {
      border: none;
    }

    &.no-separator {
      border-color: transparent;
    }
  }
}

a {
  white-space: nowrap;
}

.accessibilityIcon {
  width: 49px;
  height: 18px;
}
</style>

<style lang="scss" brand="mcom" scoped>
a {
  color: $white;
}
</style>
