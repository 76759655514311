/**
* Example usage for triggering Shift+Tab to move focus back to the input field
*/
function triggerShiftTab(searchInput) {
  if (searchInput) {
    const shiftTabEvent = new KeyboardEvent('keydown', {
      key: 'Tab',
      code: 'Tab',
      shiftKey: true,
      bubbles: true,
      cancelable: true,
    });
    searchInput.dispatchEvent(shiftTabEvent);
  } else {
    console.error('Element with "searchInput" not found.'); // eslint-disable-line no-console
  }
}

/**
* Example usage for improving accessibility by adding keyboard event listeners
*/
function useSearchModalAccessibility({ searchModalEl }) {
  function setTabListener() {
    if (searchModalEl.value) {
      searchModalEl.value.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          const focusableElements = searchModalEl.value.querySelectorAll('[tabindex="0"]');
          const lastFocusable = focusableElements[focusableElements.length - 1];

          if (document.activeElement === lastFocusable && !event.shiftKey) {
            // note that we are using querySelector to target the input.
            // The "best" solution would have been to setup a ref on the component
            // but it's not working, and return an endless nested object.
            // This needs further investigation.
            // As an alternative solution, the approach below works as well.
            const searchInput = searchModalEl.value.querySelector('input');
            searchInput.focus();
            triggerShiftTab(searchInput);
          }
        }
      });
    }
  }

  return { setTabListener };
}

export default useSearchModalAccessibility;
