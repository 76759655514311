import { inject, provide } from 'vue';

export default function useProvideFactory({ key, factory, useAsInject }) {
  const data = inject(key, undefined);

  if (data) {
    return useAsInject ? data : undefined;
  }

  const values = factory();

  if (useAsInject) {
    return values;
  }

  return provide(key, values);
}
