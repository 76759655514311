const viewTypes = {
  desktop: 'Desktop',
  mobile: 'Mobile',
};

const dataSources = {
  aem: 'aem',
  aemWithMenuFromStella: 'aemWithMenuFromStella',
};

const deviceTypes = {
  desktop: 'DESKTOP',
  phone: 'PHONE',
};

const viewTypeToDeviceType = {
  [viewTypes.desktop]: deviceTypes.desktop,
  [viewTypes.mobile]: deviceTypes.phone,
};

const defaultContext = {
  viewType: 'Default',
  deviceType: 'DESKTOP',
  regionCode: 'US',
  currencyCode: 'USD',
};

const storeOperationStates = {
  OPEN: 'open',
  CLOSE: 'close',
};

const DESKTOP_MINIMUM_SCREEN_WIDTH = 1024;
const MAX_BAG_COUNT = 99;

const loyallist = 'LOYALLIST';

const PAY_BILL_LINK_TEXT_VARIATIONS = [
  'pay your credit card bill',
  'pay my bill',
];

const SEARCH_PAGE_PREFIXES = {
  featured: '/shop/featured/',
  keyword: '/shop/search?keyword=',
};

module.exports = {
  viewTypes,
  deviceTypes,
  dataSources,
  viewTypeToDeviceType,
  defaultContext,
  storeOperationStates,
  DESKTOP_MINIMUM_SCREEN_WIDTH,
  MAX_BAG_COUNT,
  loyallist,
  PAY_BILL_LINK_TEXT_VARIATIONS,
  SEARCH_PAGE_PREFIXES,
};
