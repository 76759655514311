import { watchEffect, ref } from 'vue';
import { useStore } from 'vuex';

import { isSSR } from '../../utils/isSSR';
import { viewTypes, DESKTOP_MINIMUM_SCREEN_WIDTH } from '../../constants';
import { useWindowResize } from './useWindowResize';
import useProvideFactory from './useProvideFactory';

export const VIEW_TYPE_REPOSITORY = Symbol('VIEW_TYPE_REPOSITORY');

export function getViewTypeSharedState() {
  const { width } = useWindowResize();
  const viewType = ref(null);
  return { width, viewType };
}

export function useViewTypeProvide(useAsInject) {
  return useProvideFactory({
    key: VIEW_TYPE_REPOSITORY,
    factory: getViewTypeSharedState,
    useAsInject,
  });
}

export default function useInit({ isPhone }) {
  const { width, viewType } = useViewTypeProvide(true);

  if (viewType.value) {
    return { viewType };
  }

  const store = useStore();

  if (!viewType.value) {
    viewType.value = store.state.navViewTypeStore.viewType; // set default value from store
  }

  watchEffect(() => {
    if (isSSR()) {
      viewType.value = isPhone ? viewTypes.mobile : viewTypes.desktop;
      return;
    }

    // this line is needed for watchEffect to track asynchronous dependency from setTimeout
    width.value; // eslint-disable-line

    // detect new viewType asynchronously to prevent hydration errors for tablet devices with 1024px screen size
    setTimeout(() => {
      const isDesktopScreen = width.value >= DESKTOP_MINIMUM_SCREEN_WIDTH; // TODO replace with composition check
      viewType.value = isDesktopScreen ? viewTypes.desktop : viewTypes.mobile;
    });
  });

  return { viewType };
}
