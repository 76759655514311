<template>
  <LazyHydrationWrapper :when-visible="params">
    <BaseFooter v-bind="$attrs" />
  </LazyHydrationWrapper>
</template>

<script setup>
import { LazyHydrationWrapper } from '../modules/lazy-hydration';
import BaseFooter from './BaseFooter.common';

const params = { rootMargin: '50px' };
</script>
