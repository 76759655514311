function getSiteMode(context = {}) {
  const { isInternational } = context;

  if (isInternational) return 'intl';
  return 'us';
}

function getBCOMLinkPrefixBySiteMode(context) {
  const siteMode = getSiteMode(context);
  const config = {
    intl: 'NAVIGATION_INTL-_-TOP_NAV',
    reg: 'NAVIGATION_REG-_-TOP_NAV',
    us: 'NAVIGATION-_-TOP_NAV',
  };

  return config[siteMode];
}

module.exports = { getSiteMode, getBCOMLinkPrefixBySiteMode };
