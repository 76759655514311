/* istanbul ignore file */

const { getSiteMode } = require('../../utils/getSiteMode');

const LINKS = {
  homepage: {
    default: {
      url: '/',
      tracking: {
        lid: (context) => `glbtopnav_macys_icon-${getSiteMode(context)}`,
      },
    },
    registry: {
      url: '/registry',
    },
    orderTracking: {
      url: '/service/contact/international/order.jsp',
      tracking: {
        cm_sp: 'navigation-_-top_nav-_-order_tracking',
        lid: (context) => `glbtopnav_order_tracking-${getSiteMode(context)}`,
      },
    },
    stores: {
      url: (context) => `https://customerservice-macys.com/articles/what-is-macys-international-shipping-policy/?intnl=${getSiteMode(context) === 'intl'}`,
      tracking: {
        cm_sp: 'navigation-_-top_nav-_-stores',
        lid: (context) => `glbtopnav_stores-${getSiteMode(context)}`,
      },
    },
    shippingTo: {
      url: '/international/context',
      tracking: {
        cm_sp: 'navigation-_-top_nav-_-shipping_to',
        lid: (context) => `glbtopnav_shipping_to_VG-${getSiteMode(context)}`,
      },
    },
    account: {
      url: '/myaccount/home',
      tracking: {
        cm_sp: 'navigation-_-top_nav-_-my_account',
      },
    },
  },
  accessibility: {
    default: {
      url: '/m/accessibility/index',
      tracking: {
        cm_sp: 'navigation-_-bottom_nav-_-accessibility',
      },
    },
  },
  loyalty: {
    default: {
      url: '/loyalty/starrewards',
      tracking: {
        lid: (context) => `glbhdrnav_star_rewards-${getSiteMode(context)}`,
      },
    },
  },
  signup: {
    default: {
      url: 'https://emails.macys.com/pub/rf?_ri_=X0Gzc2X%3DYQpglLjHJlTQGjYzezgdzgFmafpLshzdrXaXI5v0lf7hIOTwVwjpnpgHlpgneHmgJoXX0Gzc2X%3DYQpglLjHJlTQGribd0fNzbs2EbuCkKzdRzcFzghN97mv2qzazc&SRCC=60&RDN=1',
      tracking: {
        link_name: 'glbbtmnav_sign_up_for_emails-us',
        lid: (context) => `glbbtmnav_sign_up_for_emails-${getSiteMode(context)}`,
      },
    },
  },
  account: {
    signIn: {
      url: '/account/signin',
      tracking: {
        lid: (context) => `glbtopnav_sign_in-${getSiteMode(context)}`,
      },
    },
    signOut: {
      url: '/account/logout',
      tracking: {
        lid: (context) => `glbtopnav_sign_out-${getSiteMode(context)}`,
      },
    },
    // TODO talk with customer pref team to get missing requirements like deviceFingerPrint and soft sign in
    // /signin/signout.ognc?lid=glbtopnav_sign_out-us&deviceFingerPrint=2859862657
  },
  giftCards: {
    url: '/shop/gift-cards/all-occasions?id=30668',
    tracking: {
      cm_sp: 'mew_navigation-_-menu-_-gift_cards',
    },
  },
  customerService: {
    internationalRegistry: {
      url: 'https://customerservice-macys.com/articles/can-i-create-a-wedding-registry-if-i-live-outside-of-the-united-states',
    },
  },
  registry: {
    signIn: {
      url: '/registry/wedding/regsignin/',
    },
  },
  creditCard: {
    apply: {
      url: 'https://citiretailservices.citibankonline.com/apply/macys-credit-card?app=UNSOL&siteId=PLCN_MACYS&langId=EN&sc=80002&cmp=A~W~U~Z~4~7~ZZZ0~CL~MA~ZZ',
      tracking: {
        cm_sp: 'navigation-_-bottom_nav-_-apply_now',
        lid: 'glbbtmnav_apply_now-us',
      },
    },
    details: {
      url: '/p/credit-service/learn-and-apply/?acquisitionOrigin=GlbNavFooterLnApply',
      tracking: {
        cm_sp: 'macys_account-_-macys_credit_card-_-apply_and_learn',
        lid: 'glbbtmnav_apply_now-us',
      },
    },
  },
};

module.exports = LINKS;
