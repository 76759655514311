<template>
  <Skeleton
    v-if="!isAllDataReady"
    height="24px"
    width="24px"
  />
  <button
    v-else-if="showProfile"
    class="rail-btn-hover rail-btn-nowrap"
    @click="openAccountSlideout"
  >
    <i class="icon-24x24 profile-icon-condensed margin-right-xxs" />
    <AsideText v-if="stats">
      <template #title>
        Hi, {{ username }}
      </template>
      <template #text>
        {{ stats }}
      </template>
    </AsideText>
    <span
      v-else
      class="grid-x heavy-sm"
    >Hi, {{ username }}</span>
  </button>
  <div
    v-else
    class="relative"
  >
    <Link
      :href="Links.account.signIn.url"
      :tracking="Links.account.signIn.tracking"
      class="rail-btn-hover rail-btn-nowrap undecorated-link"
      @click="handleAccountLinkClick"
      @focusin="deactivateSignInTooltip"
    >
      <i class="icon-24x24 profile-icon-condensed margin-right-xxs" />
      <span class="grid-x heavy-sm">Sign In</span>
    </Link>
    <SignInTooltip
      v-if="isSignInTooltipActive"
      class="sign-in-tooltip--enchanced"
    >
      <template #default="{ suspendTimer, resumeTimer }">
        <span
          v-if="isMcom"
          class="small"
        >See more of <br> what you love</span>
        <span
          v-else
          class="small"
        >For the best <br> experience:</span>
        <a
          :href="Links.account.signIn.url"
          :class="{ 'primary': isMcom, 'secondary': !isMcom }"
          class="button button-small"
          @focusin="suspendTimer"
          @focusout="resumeTimer"
          @click="useSignInClick({ tooltipTags: true })"
        >
          Sign In
        </a>
      </template>
    </SignInTooltip>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Skeleton from '@atomic-ui/skeleton';
import Link from '../common/Link';
import AsideText from './AsideText.common';
import SignInTooltip from '../link-rail/SignInTooltip.common';
import Links from '../../constants/Links';
import useAccount from '../../composables/useAccount';
import useLoyalty from '../../composables/useLoyalty';
import useAccountSlideout from '../../composables/features/useAccountSlideout';
import useSignInClick from '../../composables/useSignInClick';
import { useSignInTooltipActivator } from '../../composables/features/useSignInTooltip';
import { tagAccountLink } from '../../features/analytics/linkRailTags';
import { onRewardsReady, onUserInit } from '../../events';

const store = useStore();
const userReady = ref(false);
const rewardsReady = ref(false);
const { isMcom } = store.state.envProps;
const { isSignedIn, username } = useAccount();
const { rewards, bothPoints } = useLoyalty();
const { openAccountSlideout } = useAccountSlideout();
const isAllDataReady = computed(() => {
  if (!userReady.value) {
    return false;
  }

  return isSignedIn.value ? rewardsReady.value : true;
});
const showProfile = computed(() => isSignedIn.value && rewardsReady.value);
const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
const { isSignInTooltipActive, deactivateSignInTooltip } = useSignInTooltipActivator({ isSignedIn, isDomestic });
const stats = computed(() => {
  if (isMcom) {
    const { balance } = rewards.value;

    if (balance) return `$${balance} Star Money`;
    if (bothPoints.value) return `${bothPoints.value} points`;
    return '';
  }

  const { balance } = rewards.value;

  if (balance) return `$${balance} Rewards`;
  return bothPoints.value
    ? bothPoints.value
      .split('/')
      .map((point) => parseInt(point, 10).toLocaleString('en-US'))
      .join('/')
    : '';
});

onUserInit(() => {
  userReady.value = true;
});

onRewardsReady(() => {
  rewardsReady.value = true;
});

function handleAccountLinkClick() {
  if (!isSignedIn.value) {
    useSignInClick();
  }
  tagAccountLink();
}
</script>

<style lang="scss">
.sign-in-tooltip--enchanced {
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  &::before, &::after {
    display: none;
  }
}
</style>
