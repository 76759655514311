<template>
  <div
    id="nav-header-root"
    @click="popupLinkHandler"
  >
    <SkipToMainContent />
    <BaseHeader
      v-bind="attrsWithOverrides"
    />
    <MetaData />
    <MainContentStart />
    <Overlay />
    <SearchModal />
    <InternationalContextSlideout v-if="mounted" />
    <AccountSlideout v-if="mounted && experiments.accountDropdownEnabled" />
    <StoreSlideout v-if="isLocatorSlideoutRendered" />
    <PopupWrapper
      v-if="popupMediaContent"
      :popup-media-content="popupMediaContent"
    />
  </div>
</template>

<script setup>
/* istanbul ignore file */
import {
  computed, defineAsyncComponent, onBeforeMount, onBeforeUnmount, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useMounted } from '@vueuse/core';
import useInternationalSlideout from '../composables/features/useInternationalSlideout';
import useExperiments from '../composables/useExperiments';
import { useInitHeader } from '../composables/useInit';
import { useAttrsOverrides } from '../composables/dumb/useAttrsOverrides';
import { useInitExperimentation } from '../features/experimentation/useInit';
import {
  SET_ATTRS, SET_COUNT, SET_FOOTER_DATA, SET_HEADER_DATA,
} from '../types/mutations';
import { LOAD_USER } from '../types/actions';
import { NAV_QUICK_BAG_STORE } from '../types/names';
import { useLocationUpdate } from '../composables/useLocatorInit';
import { usePopupLink } from '../composables/features/usePopupLink';
import dispatchNavigationReady from '../utils/dispatchNavigationReady';

import SkipToMainContent from './common/SkipToMainContent';
import MainContentStart from './common/MainContentStart';
import SearchModal from './search/SearchModal.common';
import Overlay from './common/Overlay.common';
import MetaData from './common/MetaData.common';
import InternationalContextSlideout from './common/InternationalContextSlideout.common';
import AccountSlideout from './common/AccountSlideout.common';
import BaseHeader from './BaseHeader';
import useKillswitches from '../composables/useKillswitches';
import { onKsFlagsReady } from '../events';
import { viewTypes } from '../constants';
import { IS_STATE_RESTORATION_FROM_DOM_ENABLED, restoreDesktopMenu, restoreMobileMenu } from '../utils/restoreStateFromDom';
import { isSSR } from '../utils/isSSR';

const PopupWrapper = defineAsyncComponent(() => import(/* webpackChunkName: "PopupWrapper" */ './common/PopupWrapper'));
const StoreSlideout = defineAsyncComponent(() => import(/* webpackChunkName: "LocatorSlideout" */ './store-slideout/StoreSlideout'));

const { openInternationalSlideout } = useInternationalSlideout();
const attrsWithOverrides = useAttrsOverrides();
const store = useStore();
const { popupMediaContent, popupLinkHandler } = usePopupLink();
const killswitches = useKillswitches();

const mounted = useMounted();

// move header data from pageData to headerStore to prevent additional xapi call
if (isSSR()) {
  const viewType = store.state.pageData.navigation.context.isPhone ? viewTypes.mobile : viewTypes.desktop;

  store.commit(`headerStore/${SET_HEADER_DATA}`, {
    data: store.state.pageData.headerData[viewType],
    viewType,
  });
  store.commit(`footerStore/${SET_FOOTER_DATA}`, {
    data: store.state.pageData.footerData,
  });
  store.dispatch('navKillswitchStore/applyKillswitches', store.state.pageData.filteredKillswitches);
  delete store.state.pageData.headerData;
  delete store.state.pageData.footerData;
  delete store.state.pageData.filteredKillswitches;
}

const isLocatorSlideoutRendered = computed(() => store.state.navPreferredStore.locatorSlideoutRendered);

store.commit(`headerStore/${SET_ATTRS}`, attrsWithOverrides);
const { isMinimal = false } = attrsWithOverrides;

useInitExperimentation();

const experiments = useExperiments();

if (!isMinimal) {
  useInitHeader();
  useLocationUpdate();
}

function handleWelcomeMatLinkClick(e) {
  const welcomeMatShipLinkSelector = '#notshipLink a';

  if (e.target.closest(welcomeMatShipLinkSelector)) {
    e.preventDefault();
    const welcomeMatModal = window.TINY && window.TINY.box;

    if (welcomeMatModal) {
      welcomeMatModal.hide();
    }

    openInternationalSlideout();
  }
}

function handleUpdateQuickBagCountEvent({ detail: count }) {
  store.commit(`${NAV_QUICK_BAG_STORE}/${SET_COUNT}`, count);
}

function handleUpdateNavigationIntContextEvent({ detail }) {
  const { regionCode, currencyCode } = detail;

  if (!regionCode) return;

  const isDomestic = regionCode === 'US';

  store.state.pageData.navigation.context.isDomestic = isDomestic;
  store.state.pageData.navigation.context.isInternational = !isDomestic;
  store.state.pageData.navigation.context.regionCode = regionCode;
  store.state.pageData.navigation.context.currencyCode = currencyCode;
}

onMounted(() => {
  if (isMinimal) return;

  onKsFlagsReady(() => {
    if (killswitches.value.internationalContextEnabled) {
      document.addEventListener('click', handleWelcomeMatLinkClick);
      window.addEventListener('updateNavigationIntContext', handleUpdateNavigationIntContextEvent);
      window.addEventListener('openNavigationIntContext', openInternationalSlideout);
    }
  });

  window.addEventListener('updateQuickBagCount', handleUpdateQuickBagCountEvent);

  dispatchNavigationReady();
  store.dispatch(`navUser/${LOAD_USER}`);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleWelcomeMatLinkClick);
  window.removeEventListener('updateQuickBagCount', handleUpdateQuickBagCountEvent);
  window.removeEventListener('updateNavigationIntContext', handleUpdateNavigationIntContextEvent);
  window.removeEventListener('openNavigationIntContext', openInternationalSlideout);
});

onBeforeMount(() => {
  // only run menu restoration for SSR pages
  if (window.__NAVIGATION_STATE__ && IS_STATE_RESTORATION_FROM_DOM_ENABLED && !isMinimal) {
    const { isMcom } = store.state.envProps;
    const performanceTimerName = '[navigation-wgl] menu parse time';
    const { viewType } = store.state.navViewTypeStore;
    const data = store.state.headerStore.data[viewType];
    const withOrder = !isMcom;

    // eslint-disable-next-line no-console
    console.time(performanceTimerName);
    if (viewType === viewTypes.desktop) {
      const isExperiment = experiments.value.enhancedDesktopNavEnabled;
      const { desktopMenu, menu } = restoreDesktopMenu(withOrder, isExperiment);

      if (isExperiment) {
        data.menu = menu;
        data.desktopMenu = desktopMenu;
      } else {
        data.menu = desktopMenu;
      }
    } else {
      const withGroups = experiments.value.enhancedMobileNavEnabled;
      data.menu = restoreMobileMenu(withOrder, withGroups);
    }

    // eslint-disable-next-line no-console
    console.timeEnd(performanceTimerName);
  }
});
</script>

<style lang="scss">
@import "../scss/common/reset.scss";
@import "../scss/common/skeleton-loading.scss";

.nav-visible {
  width: 100%;
  position: fixed;
}

@media only screen and (max-width: 1023px) {
  header.desktop {
    @include skeleton-loading();
  }
}

@media only screen and (min-width: 1024px) {
  header.mobile {
    @include skeleton-loading();
  }
}
</style>

<style lang="scss" brand="mcom">
@import "../scss/classes.mcom.scss";
@import "../scss/icons.mcom.scss";
</style>

<style lang="scss" brand="bcom">
@import "../scss/classes.bcom.scss";
@import "../scss/icons.bcom.scss";
</style>
