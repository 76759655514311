import { fireLinkTag } from './tagManager';
import { getSiteMode } from '../../utils/getSiteMode';

export default function sendTrackParams(tracking, context = {}) {
  if (!tracking) return;

  const trackingPayload = Object.entries(tracking).reduce((acc, cur) => {
    const [key, value] = cur;

    acc[key] = typeof value === 'function' ? value(context) : value;
    return acc;
  }, {});

  const payload = {
    event_name: 'nav link click',
    category_id: '',
    top_nav_category_name: '',
    primary_component: 'glbhdrnav',
    clickable_element: '',
    col: '',
    pos: '',
    mode: getSiteMode(context),
    ...trackingPayload,
  };

  fireLinkTag(payload);
}
