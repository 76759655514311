<template>
  <template
    v-for="fob in fobs"
    :key="fob.text"
  >
    <LazyHydrationWrapper
      v-if="fob.children.length"
      :when-triggered="fob.text === flyoutCategoryName"
    >
      <Flyout
        :flyout-category-name="fob.text"
        :is-active="fob.text === flyoutCategoryName"
        @close="close"
      />
    </LazyHydrationWrapper>
  </template>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Flyout from './Flyout.common';
import { useHeaderMenuData } from '../../composables/useHeaderMenuData';
import { LazyHydrationWrapper } from '../../modules/lazy-hydration';

const emit = defineEmits(['close']); // eslint-disable-line no-undef

defineProps({ // eslint-disable-line no-undef
  flyoutCategoryName: {
    type: String,
    required: true,
  },
});

const store = useStore();
const { getFlyouts } = useHeaderMenuData(store);
const fobs = computed(() => getFlyouts());

function close() {
  emit('close');
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" brand="mcom" scoped>
</style>

<style lang="scss" brand="bcom" scoped>
</style>
