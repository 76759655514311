/* eslint-disable no-shadow */
import { Cookie } from '@common-vue/util/src/modules/cookie';
import { LOAD_USER } from '../../types/actions';
import { SET_USER_DATA } from '../../types/mutations';

const storeConstants = [
  'USERPC',
  'BTZIPCODE',
  'BOPSPICKUPSTORE',
  'USERLL',
];

const getLatLng = (storeCookies) => {
  let lat = '';
  let lng = '';
  const latNlng = storeCookies.USERLL;
  if (latNlng && latNlng.includes(',')) {
    const v = latNlng.split(',');
    if (v.length >= 2) {
      [lat, lng] = v;
    }
    return {
      lat,
      lng,
    };
  }
  return null;
};

const parseMISCGCsCookie = (MISCGCs, storeConstants) => {
  const storeCookies = {};
  let userName = null;
  let tokenExpiry = null;

  if (MISCGCs) {
    const cookiesArray = MISCGCs.split('3_87_');
    cookiesArray.forEach((cookie) => {
      const [cookieName, cookieValue] = cookie.split('1_92_');
      if (cookie.includes('UserName')) {
        userName = cookieValue;
      }
      if (cookie.includes('STExpiry')) {
        tokenExpiry = cookieValue;
      }
      if (storeConstants.includes(cookieName)) {
        storeCookies[cookieName] = cookieValue;
      }
    });
  }

  return { userName, tokenExpiry, storeCookies };
};

export function state() {
  return {
    data: {},
    initialized: false,
  };
}

export const mutations = {
  [SET_USER_DATA](state, payload) {
    state.data = payload;
    state.initialized = true;
  },
};

export const actions = {
  [LOAD_USER]({ commit, rootState }) {
    const { isMcom } = rootState.envProps;
    const allCookies = Cookie.parse();
    const { SignedIn = '0', MISCGCs = '' } = allCookies;
    const { userName, tokenExpiry, storeCookies } = parseMISCGCsCookie(MISCGCs, storeConstants);
    const guid = isMcom ? allCookies.macys_online_guid : allCookies.bloomingdales_online_guid;
    const uid = isMcom ? allCookies.macys_online_uid : allCookies.bloomingdales_online_uid;
    const isTokenAlive = Date.now() < Number(tokenExpiry);
    const data = {
      isSignedIn: SignedIn === '1' && isTokenAlive,
      username: userName,
      storeInfo: {
        defaultZipcode: storeCookies.USERPC,
        // btZipcode: storeCookies.BTZIPCODE,
        // bopsPickupStore: storeCookies.BOPSPICKUPSTORE,
        latLng: getLatLng(storeCookies),
      },
      guid,
      uid,
    };

    commit(SET_USER_DATA, data);
  },
};
