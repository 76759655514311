<template>
  <div class="link-wrap grid-x align-middle">
    <button
      class="menu-link-sm"
      @click.prevent="openInternationalSlideout"
    >
      {{ firstLinkText }}
    </button>

    <Flag
      width="12px"
      height="12px"
      class="rounded margin-left-xxs"
    />

    <button
      v-if="!isDomestic"
      class="margin-left-xxs menu-link-sm"
      @click="handleGoToUSSite"
    >
      Go to U.S. site
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Cookie } from '@common-vue/util/src/modules/cookie';

import useInternationalSlideout from '../../composables/features/useInternationalSlideout';
import Flag from '../common/Flag.common';

const store = useStore();

const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
const currencyCode = computed(() => store.state.pageData.navigation.context.currencyCode);
const firstLinkText = computed(() => (isDomestic.value ? currencyCode.value : 'Shipping To'));
const { openInternationalSlideout } = useInternationalSlideout();

function handleGoToUSSite() {
  Cookie.set('shippingCountry', 'US');
  Cookie.set('currency', 'USD');
  window.location.assign('/');
}
</script>

<style scoped lang="scss" brand="mcom">
.menu-link-sm {
  color: $white;
}
</style>

<style scoped lang="scss" brand="bcom">
.menu-link-sm {
  font-weight: 300;
}
</style>
