<template>
  <div class="grid-y">
    <SignUp
      v-if="!isInternational"
      class="footer-section"
    />
    <CreditCard
      v-if="!isInternational"
      class="footer-section"
    />
    <SocialLinks
      class="footer-section"
      :class="{'social-links': isInternational}"
    >
      <span class="white">Connect With Us</span>
    </SocialLinks>
    <AppSection
      v-if="!isInternational"
      class="footer-section footer-apps"
      title="Macy's"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CreditCard from './CreditCard.mcom';
import SignUp from './SignUp.mcom';
import SocialLinks from './SocialLinks.common';
import AppSection from './AppSection.common';

const store = useStore();
const isInternational = computed(() => store.state.pageData.navigation.context.isInternational);
</script>

<style lang="scss" brand="mcom" scoped>
@import "../../scss/mixins.mcom.scss";

.footer-section {
  position: relative;
  @include padding-top-m;
  @include padding-bottom-m;

  &:not(:first-child) {
    &::before {
      position: absolute;
      top: 0;
      left: -20px;
      width: 100vw;
      height: 1px;
      content: '';
      background-color: $gray-1-color;

      @include breakpoint(large up) {
        left: 0;
        width: 100%;
      }
    }
  }

  &:last-child {
    @include padding-bottom-z;
    border-bottom: none;
  }

  @include breakpoint(large up) {
    &:first-child {
      @include padding-top-z;
    }
  }
}
.social-links {
  @include padding-top-z;
}

@include breakpoint(large up) {
  .footer-apps {
    display: none;
  }
}
</style>
