<template>
  <div
    class="sign-in-tooltip"
    :class="{ 'sign-in-tooltip--transparent': isTransparent }"
    @mouseenter="suspendTimer"
    @mouseleave="resumeTimer"
  >
    <slot v-bind="{ suspendTimer, resumeTimer, tagSignInClick }" />
  </div>
</template>

<script setup>
import { useSignInTooltip } from '../../composables/features/useSignInTooltip';

const {
  isTransparent, suspendTimer, resumeTimer, tagSignInClick,
} = useSignInTooltip();
</script>

<style lang="scss">
.sign-in-tooltip {
  position: absolute;
  padding: 8px 8px 8px 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  box-shadow: $drop-shadow-bottom;
  z-index: 2;

  &--transparent {
    opacity: 0;
    pointer-events: none;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    bottom: 100%;
    position: absolute;
  }

  span {
    margin-right: 12px;
    width: 80px;
  }
}
</style>

<style lang="scss" brand="mcom">
.sign-in-tooltip {
  top: calc(100% + 8px);
  background-color: $white;
  border: 1px solid $gray-4-color;
  border-radius: 5px;
  right: 34px;

  &::before {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 11px solid $gray-4-color;
    right: 9px;
  }

  &::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid $white;
    right: 10px;
  }

  span {
    width: 80px;
  }
}
</style>

<style lang="scss" brand="bcom">
.sign-in-tooltip {
  top: calc(100% + 9px);
  background-color: $css-tooltip-background-color;
  right: -33px;

  &::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid $css-tooltip-background-color;
    position: absolute;
    right: 42px;
  }

  span {
    color: $white;
    font-weight: 700;
  }
}
</style>
