import { Cookie } from '@common-vue/util/src/modules/cookie';
import signInTooltipTags from '../features/analytics/signInTooltipTags';

export default function useSignInClick(params = {}) {
  const {
    redirectUrl = window.location.href,
    tooltipTags = false,
  } = params;

  Cookie.set('SignedIn', '0');
  Cookie.set('FORWARDPAGE_KEY', redirectUrl);

  if (tooltipTags) {
    signInTooltipTags();
  }
}
