<template>
  <div class="media-rail text-center">
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="promoMessage"
      class="small"
      @click="tagBannerClick"
      v-html="promoMessage"
    />
    <!-- eslint-enable -->
    <Skeleton
      v-else
      :height="'14px'"
    />
  </div>
</template>

<script setup>
/* istanbul ignore file */
import { watch } from 'vue';
import Skeleton from '@atomic-ui/skeleton';

import { tagBannerLinkClick } from '../../features/analytics/mediaRailTags';
import usePromoMessage from '../../composables/usePromoMessage';
import dispatchNavigationAdBannerRendered from '../../utils/dispatchNavigationAdBannerRendered';

const { promoMessage, promoMessageLoaded } = usePromoMessage();

const tagBannerClick = (event) => {
  const { target: { href } } = event;

  tagBannerLinkClick(href);
};

watch(promoMessageLoaded, (value) => {
  if (value) {
    setTimeout(() => {
      dispatchNavigationAdBannerRendered();
    });
  }
});
</script>

<style lang="scss" brand="mcom" scoped>
@import "../../scss/mixins.mcom.scss";

.media-rail {
  border-bottom: 1px solid $gray-4-color;
  padding-top: 12px;
  padding-bottom: 12px;

  @include breakpoint(large up) {
    border: none;
    padding: 0;
  }
}

:deep(a) {
  text-decoration: underline;
}

:deep(span) {
  // The data from astra is coming with style="text-decoration:underline"
  // TODO remove this once we migrate to aem, so we can have this fixed in aem data
  text-decoration: none !important;
}
</style>
