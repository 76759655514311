import ObjectUtil from '@core/util/object/index';

const OPEN_TEXT = 'Open';
const CLOSED_TEXT = 'Closed';

const _isFullSchedule = (schedule) => schedule.openTime && schedule.closeTime;

const _get12HourFormat = (hour) => hour % 12 || 12;

const _getSuffix = (hour) => (hour >= 12 ? 'PM' : 'AM');

const _getTimeString = (hour, minute) => {
  const hour12 = _get12HourFormat(hour);
  const suffix = _getSuffix(hour);
  const minString = minute.toString().padStart(2, '0');

  return minute > 0
    ? `${hour12}:${minString} ${suffix}`.trim()
    : `${hour12} ${suffix}`.trim();
};

function _parseTime(timeStr) {
  if (!timeStr) {
    return [null, null];
  }

  const twelveHoursMatch = timeStr.match(/(\d{1,2}(:\d{1,2})?)\s?(am|pm)/i);

  if (twelveHoursMatch) {
    const [hours, minutes = 0] = twelveHoursMatch[1].split(':').map(Number);

    if (twelveHoursMatch[3].toLowerCase() === 'am') {
      return [hours === 12 ? 0 : hours, minutes];
    }
    if (hours === 12) {
      return [hours, minutes];
    }

    return [hours + 12, minutes];
  }

  const [hours, minutes = 0] = timeStr.split(':').map(Number);

  return [hours === 24 ? 0 : hours, minutes];
}

function _getMMDDFormat(date = new Date()) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}`;
}

const _isOpen = (currentHour, currentMin, hoursToday) => {
  const [openHour, openMinute] = _parseTime(hoursToday.openTime);
  const [closeHour, closeMinute] = _parseTime(hoursToday.closeTime);
  const closesAfterMidnight = closeHour < openHour || (closeHour === openHour && closeMinute < openMinute);

  if (closesAfterMidnight) {
    // For stores that close after midnight
    return (currentHour > openHour)
           || (currentHour === openHour && currentMin >= openMinute)
           || (currentHour < closeHour)
           || (currentHour === closeHour && currentMin < closeMinute);
  }

  // For stores that close before midnight
  return (currentHour > openHour && currentHour < closeHour)
           || (currentHour === openHour && currentMin >= openMinute)
           || (currentHour === closeHour && currentMin < closeMinute);
};

const _formatStoreName = (name) => name
  .replace(/\s+/g, ' ')
  .replace(/'/g, '')
  .trim()
  .split(' ')
  .join('+');

const parseOperationHours = (storeDetails, date = new Date()) => {
  const currentHour = date.getHours();
  const currentMin = date.getMinutes();
  const hoursToday = ObjectUtil.getChildProperty(storeDetails, 'schedule.todaysHours');
  const hoursTmrw = ObjectUtil.getChildProperty(storeDetails, 'schedule.tomorrowsHours');
  const isValidSchedule = (hoursToday && _isFullSchedule(hoursToday)) && (hoursTmrw && _isFullSchedule(hoursTmrw));

  let operationState = '';
  let operationHours = '';

  if (isValidSchedule) {
    const [openHourToday, openMinuteToday] = _parseTime(hoursToday.openTime);
    const [closedHourToday, closedMinuteToday] = _parseTime(hoursToday.closeTime);

    const openTimeToday = _getTimeString(openHourToday, openMinuteToday);
    const closeTimeToday = _getTimeString(closedHourToday, closedMinuteToday);

    // Check special conditions
    const isOpen24Hours = openHourToday === 0 && openMinuteToday === 0 && closedHourToday === 0 && closedMinuteToday === 0;
    const isContinuousToTomorrow = hoursToday.closeTime === hoursTmrw.openTime;

    if (_isOpen(currentHour, currentMin, hoursToday)) {
      operationState = OPEN_TEXT;
      operationHours = isOpen24Hours && isContinuousToTomorrow
        ? `${closeTimeToday} ${_getMMDDFormat(new Date(hoursTmrw.operationDate?.replaceAll('-', '/')))}`
        : closeTimeToday;
    } else {
      operationState = CLOSED_TEXT;
      operationHours = openTimeToday;
    }
  }

  return {
    operationState,
    operationHours: operationState ? ` until ${operationHours}` : '',
  };
};

const getStoreLocationLink = (storeName, zip) => {
  const defaultLink = 'https://www.bloomingdales.com/stores/';

  if (!storeName || storeName.toLowerCase() === 'find a store') {
    return defaultLink;
  }
  const formattedName = _formatStoreName(storeName);

  return zip ? `${defaultLink}?q=${formattedName}+${zip}` : `${defaultLink}?q=${formattedName}`;
};

export {
  parseOperationHours,
  getStoreLocationLink,
  // for testing purposes
  _parseTime,
};
