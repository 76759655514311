import { useWindowResizeProvide } from './dumb/useWindowResize';
import { useViewTypeProvide } from './dumb/useViewType';
import { useAccountSlideOutProvide } from './features/useAccountSlideout';
import { useSignInTooltipProvide } from './features/useSignInTooltip';
import { useMobileNavProvide } from './useMobileNav';

export function useProviders() {
  useMobileNavProvide();
  useWindowResizeProvide();
  useViewTypeProvide();
  useAccountSlideOutProvide();
  useSignInTooltipProvide();
}

export function useFooterOnlyProviders() {
  useWindowResizeProvide();
  useViewTypeProvide();
}
