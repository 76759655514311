<template>
  <MobileNav
    v-show="mounted"
    ref="$mobileMenu"
    @close="closeNav"
  >
    <LazilyHydratedAsyncMenu v-if="headerData" />
  </MobileNav>
</template>

<script setup>
/* istanbul ignore file */
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useEventBus, useEventListener } from '@vueuse/core';

import { hydrateWhenTriggered } from '../../modules/lazy-hydration';
import { GET_HEADER_DATA } from '../../types/getters';
import { OVERLAY, events } from '../../constants/eventBus';
import { onBrowserIdle } from '../../events';
import { useMobileNav } from '../../composables/useMobileNav';
import MobileNav from './MobileNav.common';

const store = useStore();
const mounted = ref(false);
const $mobileMenu = ref(null);
const { isNavSSR } = store.state.envProps;
const { closeNav, isMenuOpened } = useMobileNav();
const isBrowserIdle = ref(false);
const isHydrationTriggered = computed(() => isMenuOpened.value || isBrowserIdle.value || !isNavSSR);

const headerData = computed(() => store.getters[`headerStore/${GET_HEADER_DATA}`]);
const LazilyHydratedAsyncMenu = hydrateWhenTriggered(
  () => import(/* webpackChunkName: "Nav_MobileNavContent" */ './MobileNavContent.common'),
  isHydrationTriggered,
);

onBrowserIdle(() => {
  isBrowserIdle.value = true;
}, { timeout: 300, availableIdleTime: 5 });

onMounted(() => {
  mounted.value = true;
  useEventListener(document, 'click', (e) => {
    if (
      isMenuOpened.value
        && !$mobileMenu.value.$el.contains(e.target)
        && !e.target.closest('[data-testid="burger-button"]')
        // check if target element still exists in DOM
        // as some elements with v-if may disappear before this event listener is called
        && document.body.contains(e.target)
    ) {
      closeNav();
    }
  });
});

const bus = useEventBus(OVERLAY);
bus.on((event) => {
  if (event === events[OVERLAY].CLICK && isMenuOpened.value) closeNav();
});
</script>
