/* istanbul ignore file */
const { logWarn } = require('./Logger');

function buildFooterRequestUrl({ regionCode, currencyCode }) {
  const params = [
    '_ccsFooter=true',
    '_removeCmFromUrls=true',
    '_navigationType=BROWSE',
    '_shoppingMode=SITE',
    `_regionCode=${regionCode || 'US'}`,
    `_currencyCode=${currencyCode || 'USD'}`,
  ];

  return `/xapi/navigate/v1/footer?${params.join('&')}`;
}

async function loadFooterMock(params) {
  logWarn('[navigation] Failed to request footer data from headerfooter-xapi, using mock data instead.');
  const { getFooterFallback } = await require(/* webpackChunkName: "XapiFallback" */ './XapiFallback');
  const footerMock = (await getFooterFallback(params)).value;

  // create copy of objects as we are modifying these objects inside component
  // nodejs loads static files only once when we use require() and keeps it in memory
  // so if we are modifying the data, we are modifying object in memory
  // to prevent this issue we need to create copies
  return { ...footerMock, footer: { ...footerMock.footer } };
}

module.exports = { buildFooterRequestUrl, loadFooterMock };
