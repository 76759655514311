<template>
  <section class="link-rail">
    <div class="grid-container grid-x align-middle align-justify">
      <ul class="links-wrap grid-x align-middle align-center vertical-separator">
        <li>
          <MediaRail />
        </li>
      </ul>

      <DefaultLinks v-if="isDomestic" />
      <InternationalLinks v-else />
    </div>
  </section>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';
import MediaRail from '../header-responsive/MediaRail.mcom';
import DefaultLinks from './DefaultLinks.mcom';
import InternationalLinks from './InternationalLinks.mcom';

const store = useStore();
const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
</script>

<style lang="scss" scoped brand="mcom">
@import "../../scss/classes.mcom.scss";

.link-rail {
  position: relative;
  font-size: 12px;
  border-bottom: 1px solid $gray-4-color;
  z-index: 6;
}

// TODO remove once this bug is fixed in csg
.menu-link-heavy-sm {
  font-weight: bold;
  &:hover {
    text-decoration: none;
  }
}
</style>
