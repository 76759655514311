import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useAccount() {
  const store = useStore();

  const isSignedIn = computed(() => store.state.navUser.data.isSignedIn);
  const username = computed(() => store.state.navUser.data.username);

  return { isSignedIn, username };
}
