<template>
  <div
    v-if="rviProducts.length"
    class="carousel"
    :class="{ 'with-history': searchHistory.length }"
  >
    <ProsCollection
      data-testid="product-carousel"
      :panel-config="panelConfig"
      :recommendation-config="recommendationConfig"
    />
  </div>
</template>

<script setup>
/* istanbul ignore file */
import {
  ref, computed, watch, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import { ProsCollection } from '@common-vue/pros-collection';
import prosCollectionComponentType from '@common-vue/pros-collection/src/utils/prosCollectionComponentType';

import useSearchHistory from '../../composables/dumb/useSearchHistory';

const store = useStore();
const { searchHistory } = useSearchHistory();
const rviProducts = ref('');

const panelConfig = {
  componentType: prosCollectionComponentType.RVI_CAROUSEL,
  panelType: 'rvi',
  numberOfItems: { large: 5, medium: 5.45, small: 2.45 },
  properties: { isProsOpenInNewTabEnabled: true },
};
const recommendationConfig = {
  zone: 'HNF',
};

/**
 * Handles displaying recently viewed products from localStorage.
 *
 * note: While `recentlyViewedItems`is being set through another consumer like (PDP),
 * the ProsCollection module is actually setting `recentlyViewedItems`
 * file: node_modules/@common-vue/pros-collection/src/utils/utils.js
 *
 * As a solution we shouldn't try to retrieve data too early.
 */
function handleRecentViewed() {
  const isSearchModalActive = computed(() => store.state.headerStore.isSearchModalActive);
  watch(isSearchModalActive, (value) => {
    if (value) {
      nextTick(() => {
        rviProducts.value = localStorage.getItem('recentlyViewedItems') || '';
      });
    }
  });
}

handleRecentViewed();
</script>

<style scoped lang="scss" brand="bcom">
@import '../../scss/mixins.bcom.scss';

.carousel {
  display: block;
  padding: 0;
  @include margin-top-m;
  @include margin-left-z;
  @include margin-right-z;
  @include margin-bottom-z;
  &.with-history {
    @include margin-top-l;
  }
}

:deep(h3) {
  @include header(h4);
  text-transform: uppercase;
}

@include breakpoint(large up) {
  .carousel {
    max-width: 584px;
    @include margin-top-l;
    @include margin-left-m;
    &.with-history {
      @include margin-top-xl;
    }
  }
}

@include breakpoint(mlarge up) {
  .carousel {
    max-width: 644px;
  }
}
</style>
