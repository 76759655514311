import { ref, onUnmounted } from 'vue';
import { useResizeObserver } from '@vueuse/core';

/**
 * Hook that calculates the separators for a multi row list of inline items.
 *
 * @param {Ref<HTMLElement>} $listRef - The reference to the list element.
 * @returns {Ref<boolean[]>} The separators indicating whether each list item has a separator.
 */
function useListSeparators($listRef) {
  const separators = ref([]);

  /**
   * Callback function to calculate separators based on the height changes of list items.
   *
   * @param {ResizeObserverEntry[]} entries - The array of resize observer entries.
   */
  const { stop } = useResizeObserver($listRef, (entries) => {
    // wrap with requestAnimationFrame to avoid issue https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
    requestAnimationFrame(() => {
      const [
        {
          target: { children: $items },
        },
      ] = entries;
      let topPrev = null;

      separators.value = [...$items].map(($item) => {
        const { top } = $item.getBoundingClientRect();
        const isSeparator = top === topPrev;
        topPrev = top;

        return isSeparator;
      });
    });
  });

  onUnmounted(stop);

  return { separators, stop };
}

export default useListSeparators;
