import { fireLinkTag } from './tagManager';

// un-used function
//
// export function tagSignUp() {
//   fireLinkTag({ link_name: 'glbbtmnav_sign_up_for_emails-us' });
// }

export const tagSuggestedSearch = (item, index, suggestionsLength, isRecentSearch) => {
  fireLinkTag({
    event_name: 'suggested search',
    search_suggest_link_name: item,
    search_suggest_link_type: isRecentSearch ? 'recent' : 'keyword',
    search_suggest_position: (index + 1).toString(),
    search_suggest_text: '',
    search_suggest_total: suggestionsLength.toString(),
  });
};

export const tagTypedSearch = (value) => {
  fireLinkTag({
    event_name: 'typed search keyword',
    search_keyword: value.trim(),
  });
};

// control
// should trigger only while mobile menu is open then use click on search
export const tagSearchWhileHamburgerOpen = () => {
  fireLinkTag({
    event_name: 'Search while Hamburger Open',
    link_name: 'Search while Hamburger Open',
    search_method: 'Search while Hamburger Open',
    search_action: true,
  });
};

// new megaMenu experiment
export const tagFromHamburgerSearchBar = () => {
  fireLinkTag({
    event_name: 'Hamburger Search Bar',
    link_name: 'Hamburger Search Bar',
    search_method: 'Hamburger Search Bar',
    search_action: true,
  });
};

export default {
  tagSuggestedSearch,
  tagTypedSearch,
  tagSearchWhileHamburgerOpen,
  tagFromHamburgerSearchBar,
};
