<template>
  <div
    v-if="hasSuggestions"
    class="search-suggestions"
  >
    <ul
      ref="listEl"
      class="grid-y"
    >
      <li
        v-for="(item, index) in suggestions"
        :key="item"
        role="option"
        @click="emits('select', { item: item.sourceLabel, index: index })"
      >
        <!-- eslint-disable vue/no-v-html -->
        <Link
          :href="buildUrl(item.sourceLabel, item.department)"
          class="suggestion undecorated-link text-left"
          :data-label="item.sourceLabel"
          @focus="selectTopSuggestion(item.sourceLabel)"
          @mouseover="debounceSelectTopSuggestion(item.sourceLabel)"
          @keydown.arrow-up.prevent="onKeydownArrowUp"
          @keydown.arrow-down.prevent="onKeydownArrowDown"
          v-html="item.label"
        />
        <!-- eslint-enable -->
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useDebounceFn } from '@vueuse/core';

import Link from '../common/Link';
import { GET_IS_HAVE_SUGGESTIONS } from '../../types/getters';
import { SET_TOP_SUGGESTION } from '../../types/mutations';
import getSearchPrefix from '../../utils/getSearchPrefix';
import { useSearchResultList } from '../../composables/accessibility/useSearchResultList';
import { normalizeSearchString } from '../../utils/normalizeSearchString';
import { SEARCH_PAGE_PREFIXES } from '../../constants';
import addQueryParam from '../../utils/addQueryParam';

const emits = defineEmits(['select']); // eslint-disable-line no-undef

const store = useStore();
const { isMcom } = store.state.envProps;
const listEl = ref(null);

const { onKeydownArrowUp, onKeydownArrowDown } = useSearchResultList({ listEl });

const suggestions = computed(() => store.state.navSearchSuggestionsStore.data);
const hasSuggestions = computed(() => store.getters[`navSearchSuggestionsStore/${GET_IS_HAVE_SUGGESTIONS}`]);

const selectTopSuggestion = (item) => {
  store.commit(`navSearchSuggestionsStore/${SET_TOP_SUGGESTION}`, item);
};

const debounceSelectTopSuggestion = useDebounceFn((item) => {
  if (!document.querySelector(`[data-label="${item}"]:hover`)) return;
  selectTopSuggestion(item);
}, 150);

const searchValue = computed(() => store.state.headerStore.searchValue);
const buildUrl = (label, department) => {
  const urlPrefix = getSearchPrefix(store);
  const labelWithDepartment = department ? `${label} in ${department}` : label;
  const normalizedLabel = normalizeSearchString(labelWithDepartment, urlPrefix === SEARCH_PAGE_PREFIXES.featured);
  const normalizedSearchValue = normalizeSearchString(searchValue.value, false);

  return addQueryParam(`${urlPrefix}${normalizedLabel}`, {
    cm_kws_ac: isMcom ? normalizedSearchValue : '',
    link_type: department ? 'department' : '',
    ss: true,
  });
};

defineExpose({ listEl }); // eslint-disable-line no-undef
</script>

<style lang="scss">
.ui-keyword-highlight {
  font-weight: bold;
}

.ui-keyword-department {
  display: inline-block;
  margin-left: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: $gray-1-color;
  margin-top: 4px;
}
</style>

<style scoped lang="scss">
@import '../../scss/mixins/index.scss';

a {
  color: $gray-1-color;
  display: block;
  &:hover,
  &:focus {
    color: $highlight-color;
  }
}

.search-suggestions {
  width: 100%;
  @include margin-top-s;
  margin-bottom: .5px;
}
.suggestion {
  color: $black;
}

ul {
  gap: map-get($grid-margin-gutters, 'large');
}

@include breakpoint(large up) {
  .search-suggestions {
    @include margin-top-m;
  }

  ul {
    gap: map-get($grid-margin-gutters, 'medium');
  }
}
</style>

<style scoped lang="scss" brand="mcom">
@import '../../scss/mixins.mcom.scss';
.search-suggestions {
  max-width: 625px;
}

@include breakpoint(large up) {
  .search-suggestions {
    @include margin-left-l;
    @include margin-right-l;
  }
}
</style>

<style scoped lang="scss" brand="bcom">
@import '../../scss/mixins.bcom.scss';
.search-suggestions {
  max-width: 642px;
}

@include breakpoint(large up) {
  .search-suggestions {
    @include margin-left-m;
    @include margin-right-m;
  }
}
</style>
