<template>
  <Slideout
    :id="slideoutId"
    title="International Shipping"
    @close-slideout="onSlideoutClose"
  >
    <InternationalContext
      v-if="showForm"
      @on-submit="toggleSlideout"
    />
  </Slideout>
</template>

<script setup>
/* istanbul ignore file */
import { Slideout } from '@atomic-ui/slideout';
import { defineAsyncComponent } from 'vue';
import useInternationalSlideout from '../../composables/features/useInternationalSlideout';

const slideoutId = 'int-context_navigation';
const InternationalContext = defineAsyncComponent(() => import(/* webpackChunkName: "international-context-component" */'@common-vue/international-context'));
const {
  init, showForm, toggleSlideout, onSlideoutClose,
} = useInternationalSlideout();

init(slideoutId);
</script>
