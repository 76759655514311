const SET_HEADER_DATA = 'setHeaderData';
const SET_VIEW_TYPE = 'setHeaderType';
const SET_FOOTER_DATA = 'setFooterType';
const SET_STAR_REWARDS_SUMMARY = 'setStarRewardsSummary';
const SET_STAR_REWARDS_PROMISE = 'setStarRewardsPromise';
const SET_SDD_MESSAGE = 'setSddMessage';
const SET_PREFERRED_STORE = 'setPreferredStore';
const SET_SEARCH_SUGGESTION_DATA = 'setSearchSuggestionData';
const RESET_SEARCH_SUGGESTION_DATA = 'resetSearchSuggestionData';
const SET_SEARCH_MODAL_STATE = 'setSearchModalState';
const SET_SEARCH_BAR_ANIMATE = 'setSearchBarAnimate';
const SET_ATTRS = 'setAttrs';
const SET_SEARCH_VALUE = 'setSearchValue';
const SET_COUNT = 'setCount';
const INCREASE_COUNT = 'increaseCount';
const SET_ACTIVE_OVERLAY_NAME = 'setActiveOverlayName';
const RESET_OVERLAY = 'resetOverlay';
const SET_TOP_SUGGESTION = 'setTopSuggestion';
const SET_USER_DATA = 'setUserData';
const SET_IS_STORES_LOADING = 'setIsStoresLoading';
const SET_SKIP_CONTENT_OFFSET = 'setSkipContentOffset';
const SET_REGISTRIES = 'setRegistries';
const SET_FETCH_ERROR = 'setFetchError';
const SET_SLIDEOUT_ID = 'setSlideoutId';
const SET_SHOW_INTERNATIONAL_CONTEXT_FORM = 'setShowInternationalContextForm';
const SET_POPUP_MEDIA_CONTENT = 'setPopupMediaContent';

export {
  SET_HEADER_DATA,
  SET_VIEW_TYPE,
  SET_FOOTER_DATA,
  SET_STAR_REWARDS_SUMMARY,
  SET_STAR_REWARDS_PROMISE,
  SET_SDD_MESSAGE,
  SET_PREFERRED_STORE,
  SET_SEARCH_SUGGESTION_DATA,
  RESET_SEARCH_SUGGESTION_DATA,
  SET_SEARCH_MODAL_STATE,
  SET_SEARCH_BAR_ANIMATE,
  SET_ATTRS,
  SET_SEARCH_VALUE,
  SET_COUNT,
  INCREASE_COUNT,
  SET_ACTIVE_OVERLAY_NAME,
  RESET_OVERLAY,
  SET_TOP_SUGGESTION,
  SET_USER_DATA,
  SET_IS_STORES_LOADING,
  SET_SKIP_CONTENT_OFFSET,
  SET_REGISTRIES,
  SET_FETCH_ERROR,
  SET_SLIDEOUT_ID,
  SET_SHOW_INTERNATIONAL_CONTEXT_FORM,
  SET_POPUP_MEDIA_CONTENT,
};
