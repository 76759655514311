import { fireLinkTag } from './tagManager';

export const onMenuOpen = () => {
  const data = {
    event_name: 'hamburger_click',
    link_name: 'hamburger_click',
  };

  fireLinkTag(data);
};

export const onMenuClose = () => {
  const data = {
    event_name: 'hamburger_close',
    link_name: 'hamburger_close',
  };

  fireLinkTag(data);
};

export default {
  onMenuOpen,
  onMenuClose,
};
