<template>
  <section
    v-if="footerData"
    class="social-card"
  >
    <h4
      class="white margin-bottom-xs heavy"
    >
      <span>{{ offerHeading }}</span>
    </h4>
    <div class="footer-card-content">
      <div class="cell credit-card-img margin-right-xs">
        <Picture
          image-url="https://assets.macysassets.com/dyn_img/site_ads/cc_1455724_1457104.png"
          alt=""
          :using-scene7="false"
        />
      </div>
      <div class="white small">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="offerContent" />
        <!-- eslint-enable -->
        <div>
          <Link
            v-for="action in offerActions"
            :key="action.value"
            :href="action.value"
            @click="fireAnalytics(action.text)"
          >
            {{ action.text }}
          </Link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* istanbul ignore file */
import {
  ref, watch, computed, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import Picture from '@atomic-ui/picture';
import { fireLinkTag } from '../../features/analytics/tagManager';
import Link from '../common/Link';
import { GET_FOOTER_DATA, GET_CREDIT_CARD_DATA, GET_IS_DESKTOP_VIEW } from '../../types/getters';
import addQueryParam from '../../utils/addQueryParam';

const store = useStore();

const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);
const isDesktop = computed(() => store.getters[`navViewTypeStore/${GET_IS_DESKTOP_VIEW}`]);
const footerData = computed(() => store.getters[`footerStore/${GET_FOOTER_DATA}`]);
const creditCardData = computed(() => store.getters[`footerStore/${GET_CREDIT_CARD_DATA}`]);

const offerHeading = ref('');
const offerContent = ref('');
const offerActions = ref([]);

watch(
  [isSignedIn, isDesktop, creditCardData],
  () => {
    if (!creditCardData.value) return;

    const { heading, content, actions } = creditCardData.value;
    let normalizedActions = actions;

    let scParam;
    let cmpParam;

    if (actions.length) {
      if (isDesktop.value) {
        scParam = '80002'; // Desktop scParam
        cmpParam = isSignedIn.value ? 'A~A~F~3~4~E~ZMK1~AI~MA~ZZ' : 'A~A~F~9~1~E~ZZZ0~AI~MA~ZZ';
      } else {
        scParam = '80005'; // Mobile scParam
        cmpParam = isSignedIn.value ? 'C~A~F~3~4~E~ZMK1~AI~MA~ZZ' : 'C~A~F~9~1~E~ZZZ0~AI~MA~ZZ';
      }

      const paramsToAdd = { sc: scParam, cmp: cmpParam };
      normalizedActions = actions.map((link, index) => {
        if (index) return link;

        const url = addQueryParam(link.value, paramsToAdd);

        return {
          ...link,
          value: url,
        };
      });
    }

    offerHeading.value = heading;
    offerContent.value = content;
    offerActions.value = actions;

    nextTick(() => {
      offerActions.value = normalizedActions;
    });
  },
  { immediate: true },
);

function fireAnalytics(actionText) {
  if (actionText === 'Apply Now') {
    const payload = {
      event_name: 'cc apply now',
      link_name: 'cc apply now footer',
    };
    fireLinkTag(payload);
  }
}
</script>

<style lang="scss" brand="mcom" scoped>
.credit-card-img {
  width: 57px;
}

.apply-now {
  display: block;
}

.footnote {
  color: $white;
  text-decoration: none;
}

.footnote-2 {
  @include breakpoint(large up) {
    display: inline-block;
    margin-right: 2px;
  }
}
</style>

<style lang="scss" brand="mcom">
.footer-card-content {
  display: flex;
  flex-flow: row nowrap;

  a {
    color: $white;
  }
}
</style>
