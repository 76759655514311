/* istanbul ignore file */
import { Cookie } from '@common-vue/util/src/modules/cookie';

import modules from './store_modules';

export default {
  namespaced: true,
  state() {
    return {
      isNavigationLoaded: false,
    };
  },
  modules,
  getters: {},
  actions: {},
  mutations: {
    setEnvProps(state, {
      brand, hostlink, assethost, aemDataEnabled: csrWglAemFlag, stellaMobileEnabled: csrStellaFlag, isNavSSR = false,
    }) {
      const isMcom = brand === 'mcom';
      const ssrWglAemFlag = !!window.__NAVIGATION_STATE__?.envProps?.aemDataEnabled;
      const ssrStellaFlag = !!window.__NAVIGATION_STATE__?.envProps?.stellaMobileEnabled;
      const props = {
        hostlink,
        headerFooterAssetHost: assethost,
        isMcom,
        isBcom: !isMcom,
        brand,
        aemDataEnabled: csrWglAemFlag || ssrWglAemFlag,
        stellaMobileEnabled: csrStellaFlag || ssrStellaFlag,
        isNavSSR,
      };

      if (!state.envProps) {
        state.envProps = {};
      }

      state.envProps = {
        ...props,
        ...state.envProps,
      };

      // load initial state in CSR mode since state is missin
      Object.keys(modules).forEach((key) => {
        const initialStateIsMissing = !state[key];
        const moduleState = modules[key].state;
        const shouldSetState = initialStateIsMissing && !!moduleState;

        if (shouldSetState) {
          try {
            state[key] = typeof moduleState === 'function' ? moduleState() : moduleState;
          } catch (err) {
            // eslint-disable-next-line
            console.log('store.js err ', err);
          }
        }
      });
    },
    setContextParams(state, {
      navigationType = 'BROWSE',
      regionCode: _regionCode = 'US',
      currencyCode: _currencyCode = 'USD',
      isPhone = false,
    }) {
      const regionCode = Cookie.get('shippingCountry') || _regionCode;
      const currencyCode = Cookie.get('currency') || _currencyCode;
      const isDomestic = regionCode === 'US';
      const isInternational = !isDomestic;
      const context = {
        regionCode, currencyCode, navigationType, isPhone, isDomestic, isInternational,
      };

      if (!state.pageData) {
        state.pageData = { navigation: { context } };
      } else {
        state.pageData.navigation = { ...state.pageData.navigation, context };
      }
    },
    setNavigationLoaded(state) {
      state.isNavigationLoaded = true;
    },
  },
};
