import {
  ref, onMounted, onUnmounted,
} from 'vue';
import { useResizeObserver } from '@vueuse/core';
import { isSSR } from '../../utils/isSSR';

export function useDocumentHeight() { //eslint-disable-line
  const documentHeight = ref(0);
  const updateDocumentHeight = () => {
    documentHeight.value = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight,
    );
  };
  const $document = isSSR() ? null : document.body;
  // wrap with requestAnimationFrame to avoid issue https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
  const { stop } = useResizeObserver($document, () => requestAnimationFrame(updateDocumentHeight));

  onMounted(updateDocumentHeight);
  onUnmounted(stop);

  return documentHeight;
}
