import { useStore } from 'vuex';
import { isSSR } from '../../utils/isSSR';

/* eslint-disable import/prefer-default-export */
export function useQueryParams() {
  const store = useStore();

  if (isSSR()) {
    return store.state.pageData.navigation.queryParams;
  }

  // for CSR
  const params = new URLSearchParams(window.location.search);

  return Object.fromEntries(params.entries());
}
