import { sanitize, attemptedScript } from '@common-vue/util/src/modules/sanitize';

export function commaSeparatedToArray(str) {
  if (str instanceof Array) return str;
  if (str) return str.split(',');
  return [];
}

export function securityValidation(value) {
  const isSafeValue = !attemptedScript(value);
  const preventXssValue = sanitize(value);
  return isSafeValue && Boolean(preventXssValue);
}

export function toPascalCase(s) {
  return s.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

export function splitIntoSentences(text) {
  const sentences = text.match(/[^.!?]+[.!?]+/g);
  return sentences ? sentences.map((sentence) => sentence.trim()) : [];
}

export function capitalizeFirstLetter(word) {
  return word[0]?.toUpperCase() + word?.slice(1);
}

export function lowerCaseAndCapitalizeFirstLetter(word) {
  return word[0]?.toUpperCase() + word?.slice(1).toLowerCase();
}

export function getURLParameter(name, querystring) {
  const qs = typeof querystring !== 'undefined' ? querystring : window.location.search;

  const regex = (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(`?${qs}`) || ['', ''])[1].replace(/\+/g, '%20');
  // if querystring is iso encoded catch error
  try {
    return decodeURIComponent(regex) || null;
  } catch (e) {
    return regex || null;
  }
}
