<template>
  <div
    v-show="isSearchModalActive"
    ref="searchModalEl"
    class="search-modal"
    :class="{
      'minimal-search-modal': showMinimalSearch,
      'search-modal-redesigned': enhancedMobileNavFeature,
      'search-modal-redesigned': enhancedMobileNavFeature,
    }"
    @keydown.esc="handleCloseModal"
  >
    <div
      class="content-wrapper grid-container"
      :class="{ 'minimal-content-wrapper': showMinimalSearch }"
      :style="{ marginTop: searchOffsetTop }"
    >
      <SearchInput
        class="search-input"
        @close="handleCloseModal"
        @keydown.arrow-down="onKeydownArrowDown"
      />
      <div class="results">
        <div class="results-content">
          <SearchSuggestions
            ref="suggestionsEl"
            @select="selectSuggestedSearch($event, false)"
          />
          <SearchHistory
            ref="historyEl"
            @select="selectSuggestedSearch($event, true)"
            @cleared="focusSearchInput"
          />
          <ProductRecommendations v-if="isMcom" />
          <SearchProductCarousel v-else-if="!isMcom && !hasSuggestions" />
        </div>
      </div>
      <div
        v-if="enhancedMobileNavFeature"
        class="search-modal-inner-overlay"
      />
    </div>
  </div>
</template>

<script setup>
/* istanbul ignore file */
import {
  ref, computed, watch, nextTick, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useEventBus } from '@vueuse/core';

import SearchInput from './SearchInput.common';
import { useFocusTrap } from '../../composables/dumb/useFocusTrap';
import useScrollLock from '../../composables/ui/useScrollLock';
import { useSearchModalInput } from '../../composables/accessibility/useSearchModalInput';

import { OVERLAY, events } from '../../constants/eventBus';
import { SEARCH_MODAL_OVERLAY } from '../../constants/common/overlayNames';
import {
  RESET_OVERLAY,
  SET_ACTIVE_OVERLAY_NAME,
  SET_SEARCH_MODAL_STATE,
  SET_SEARCH_BAR_ANIMATE,
} from '../../types/mutations';
import {
  GET_IS_DESKTOP_VIEW,
  GET_IS_HAVE_SUGGESTIONS,
  SHOW_MINIMAL_SEARCH,
} from '../../types/getters';
import { NAV_EXPERMENTATION_STORE } from '../../types/names';
import { tagSuggestedSearch } from '../../features/analytics/searchTags';

import useFeatureEligibility from '../../composables/useFeatureEligibility';
import useSearchHistory from '../../composables/dumb/useSearchHistory';
import SearchHistory from './SearchHistory.common';
import SearchSuggestions from './SearchSuggestions.common';
import SearchProductCarousel from './SearchProductCarousel.bcom';
import ProductRecommendations from './ProductRecommendations.mcom';
import { useQueryParams } from '../../composables/dumb/useQueryParams';
import useSearchModalAccessibility from '../../composables/accessibility/useSearchModalAccessibility';

const store = useStore();
const query = useQueryParams();

const showMinimalSearch = computed(() => query._showMinimalSearch === 'true' || store.getters[`${NAV_EXPERMENTATION_STORE}/${SHOW_MINIMAL_SEARCH}`]);

const { isMcom } = store.state.envProps;
const { enhancedMobileNavFeature } = useFeatureEligibility();

const searchModalEl = ref(null);
const suggestionsEl = ref(null);
const historyEl = ref(null);
const hasSuggestions = computed(() => store.getters[`navSearchSuggestionsStore/${GET_IS_HAVE_SUGGESTIONS}`]);
const suggestions = computed(() => store.state.navSearchSuggestionsStore.data);
const isDesktop = computed(() => store.getters[`navViewTypeStore/${GET_IS_DESKTOP_VIEW}`]);
const isSearchModalActive = computed(() => store.state.headerStore.isSearchModalActive);

const searchOffsetTop = ref(null);
const { lock, unlock } = useScrollLock();
const { activate, deactivate } = useFocusTrap(searchModalEl);
const { onKeydownArrowDown } = useSearchModalInput({ suggestionsEl, historyEl });
const { setTabListener } = useSearchModalAccessibility({ searchModalEl });
const { searchHistory, addToSearchHistory } = useSearchHistory();
const bus = useEventBus(OVERLAY);

function focusSearchInput() {
  const clearAllSearchInput = document.querySelector('.search-modal .input-search');
  const closeModalSearchInput = document.querySelector('#nav-header-root .input-search');

  if (clearAllSearchInput) {
    clearAllSearchInput.focus();
  } else if (closeModalSearchInput) {
    closeModalSearchInput.focus();
  }
}

function focusSearchModalInput() {
  const searchModalInput = document.querySelector('.search-modal .input-search');
  if (searchModalInput) {
    searchModalInput.focus();
  }
}

function handleOpenModal() {
  if (isDesktop.value) {
    window.scrollTo(0, 0);
    const logo = document.querySelector('header .nav-logo');
    const navDeals = document.querySelector('.nav-deals'); // Check for nav-deals
    const offsetTop = logo?.getBoundingClientRect()?.top || 0;
    const navDealsHeight = navDeals ? navDeals.offsetHeight : 0;

    searchOffsetTop.value = offsetTop ? `${offsetTop - navDealsHeight}px` : null;
  }

  lock();
  store.commit(`navOverlay/${SET_ACTIVE_OVERLAY_NAME}`, SEARCH_MODAL_OVERLAY);
  store.commit(`headerStore/${SET_SEARCH_MODAL_STATE}`, true);
  activate();

  setTimeout(() => {
    focusSearchModalInput();
  }, 100);
}

function handleCloseModal() {
  unlock();
  store.commit(`navOverlay/${RESET_OVERLAY}`);
  store.commit(`headerStore/${SET_SEARCH_MODAL_STATE}`, false);
  store.commit(`headerStore/${SET_SEARCH_BAR_ANIMATE}`, false);
  deactivate();

  nextTick(() => {
    const outsideSearchInput = document.querySelector('#nav-header-root .input-search');
    if (outsideSearchInput) {
      outsideSearchInput.focus();
    }
  });
}

const selectSuggestedSearch = ({ item, index }, isRecentSearch) => {
  addToSearchHistory(item);
  const suggestionsLength = suggestions.value.length || searchHistory.value.length;
  tagSuggestedSearch(item, index, suggestionsLength, isRecentSearch);
};

bus.on((event) => {
  if (event === events[OVERLAY].CLICK) handleCloseModal();
});

watch(isSearchModalActive, (newValue, oldValue) => {
  if (newValue) {
    handleOpenModal();
  } else if (newValue !== oldValue) {
    handleCloseModal();
  }
});

onMounted(() => {
  setTabListener();
});
</script>

<style scoped lang="scss">
@import '../../scss/mixins/index.scss';

:deep() {
  .input-search:focus {
    outline: none;
  }
}
.results {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &:not(:empty) {
    @include margin-top-xxs;
  }
}
.grid-container {
  @include padding-right-z;
}
@include breakpoint(large up) {
  .results-content {
    @include margin-bottom-l;
  }
  .grid-container {
    @include margin-right-z;
    @include padding-left-z;
    max-width: unset;
  }
}
.results-content {
  margin: 0 auto;
}
.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  z-index: 11;
}

.content-wrapper {
  margin-top: 80px;
}

.search-input {
  margin: 0 auto;
}

@include breakpoint(large up) {
  .search-input, .results-content {
    max-width: 648px;
  }
}

@include breakpoint(mlarge up) {
  .search-input, .results-content {
    max-width: 708px;
  }
}

.search-modal-redesigned {
  .content-wrapper {
    margin-top: 20px;
  }
}

.search-modal.search-modal-redesigned {
  z-index: 23;
}

.search-modal-inner-overlay {
  background-color: #fff;
  height: 100vh;
  opacity: 1;
  top: 0;
  transition: none;
  z-index: 22;
}

</style>

<style scoped lang="scss" brand="mcom">
.search-modal {
  min-height: 158px;
}
.minimal-content-wrapper {
  margin-top: 72px;
}

.minimal-search-modal {
  min-height: 73px !important;
}

.registry-mode {
  @media only screen and (min-width: 1024px) and (max-width: 1130px) {
    .search-input, .results-content {
      max-width: 540px;
    }
  }
}

@include breakpoint(large up) {
  .content-wrapper {
    position: relative;
    margin-top: 59px;
  }

  .minimal-content-wrapper {
    margin-top: 16px !important;
  }
}
</style>

<style lang="scss">
.nav-deals ~ .search-modal {
  padding-top: 40px;
  padding-bottom: 10px;
}
</style>

<style scoped lang="scss" brand="bcom">
.search-modal {
  min-height: 192px;
}

@include breakpoint(large up) {
  .content-wrapper {
    position: relative;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .results-content {
    margin: 0 70px 0 auto;
  }
  .search-input {
  margin: 0 188px 0 auto;
  }
}
</style>
