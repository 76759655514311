<template>
  <div
    v-for="(column, index) in links"
    :key="index"
    class="footer-links-group"
    :class="{ 'footer-links-group--last': index === links.length - 1 }"
  >
    <div class="footer-links-heading">
      <h4>{{ column.heading }}</h4>

      <button
        class="footer-links-activator"
        :aria-label="column.areaLabel"
        @click.prevent="toggle(index)"
      />
      <div
        class="footer-links-icon"
        :class="column.icon"
      />
    </div>
    <ul
      class="grid-y footer-links-content"
      :class="{ 'footer-links-expanded': column.isExpanded }"
    >
      <li
        v-for="(item, index) in column.links"
        :key="index"
      >
        <Link
          class="menu-link menu-link-sm"
          :href="item.url"
          :alt="item.alt"
          :tracking="item.tracking"
          :data-tracking="trackingObjToString(item.tracking)"
          @click="handlePayYourCreditCardLinkClick"
        >
          {{ item.alt }}
        </Link>
      </li>

      <li v-if="mounted && internationalContextEnabled && column.name === 'SITE_FOOTER_COLUMN_1'">
        <ShippingLink />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useMounted } from '@vueuse/core';

import { GET_FOOTER_LINKS } from '../../types/getters';
import { handlePayYourCreditCardLinkClick } from '../../utils/handlePayYourCreditCardLinkClick';
import useKillswitches from '../../composables/useKillswitches';
import Link from '../common/Link';
import ShippingLink from './ShippingLink.common';
import { trackingObjToString } from '../../utils/restoreStateFromDom';

const store = useStore();
const killswitches = useKillswitches();
const mounted = useMounted();
const expanded = ref([]);
const internationalContextEnabled = computed(() => killswitches.value.internationalContextEnabled);

function toggle(index) {
  expanded.value[index] = !expanded.value[index];
}

function getAreaLabel(heading, index) {
  return expanded.value[index] ? `Collapse section: ${heading}` : `Expand section: ${heading}`;
}

function getIconClass(index) {
  return expanded.value[index] ? 'footer-links-icon--expanded' : '';
}

const links = computed(() => store.getters[`footerStore/${GET_FOOTER_LINKS}`].map((column, index) => ({
  ...column,
  isExpanded: expanded.value[index],
  areaLabel: getAreaLabel(column.heading, index),
  icon: getIconClass(index),
})));
</script>

<style lang="scss" scoped>
@import "../../scss/mixins/index.scss";
$iconSize: 24px;

.footer-links {
  &-group {
    border-bottom: 1px solid $gray-3-color;

    &--last {
      @include margin-bottom-m;
    }
  }

  &-heading {
    position: relative;
    padding: 16px 32px 16px 0;
  }

  &-activator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  &-content {
    display: none;
    @include margin-bottom-s;

    li + li {
      @include padding-top-xs;
    }
  }

  &-expanded {
    display: block;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: $iconSize;
    height: $iconSize;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &::before, &::after {
      position: absolute;
      display: block;
      content: '';
    }

    &::after {
      transform: rotate(90deg);
    }

    &--expanded {
      &::after {
       display: none;
      }
    }
  }
}

@include breakpoint(large up) {
  .footer-links {
    &-group {
      border: none;

      &--last {
        margin-bottom: 0;
      }
    }

    &-heading {
      padding: 0;
    }

    &-content {
      display: block;
      @include margin-top-xs;
      margin-bottom: 0;
    }

    &-activator, &-icon {
      display: none;
    }
  }
}
</style>

<style lang="scss" brand="mcom" scoped>
h4, .menu-link  {
  color: $white;
}

h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.footer-links {
  &-icon {
    &::after, &::before {
      width: 8px;
      height: 1px;
      background-color: $white;
    }
  }
}
</style>

<style lang="scss" brand="bcom" scoped>
.menu-link {
  font-weight: 300;
}

h4 {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 14px;
  text-transform: uppercase;

  @include breakpoint(large up) {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.14px;
    line-height: 18px;
    text-transform: none;
  }
}

.footer-links {
  &-group--last {
    border-bottom: none;
  }

  &-icon {
    &::after, &::before {
      width: 10px;
      height: 2px;
      background-color: $black;
    }

    &--expanded {
      &::before {
        width: 8px;
      }
    }
  }
}
</style>
