const FETCH_SDD_MESESSAGE = 'fetchSddMessage';
const FETCH_PREFERRED_STORE = 'fetchPreferredStore';
const FETCH_SEARCH_SUGGESTIONS = 'fetchSuggestions';
const LOAD_USER = 'loadUser';
const APPLY_SEARCH_TERM = 'applySearchTerm';

export {
  FETCH_SDD_MESESSAGE,
  FETCH_PREFERRED_STORE,
  FETCH_SEARCH_SUGGESTIONS,
  LOAD_USER,
  APPLY_SEARCH_TERM,
};
