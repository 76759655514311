/*
  This file sets the adaptive view type of the application (desktop vs mobile)
  It will first set the view type on the server side by checking the device type
  Once it reaches the client side it will do another check for view type based on the screen size
  The screen size's view type will overwrite the device type-based view type and it will update viewtype if necessary
*/
import { onServerPrefetch, onMounted } from 'vue';
import { useStore } from 'vuex';

import { SETUP_CLIENT_SIDE_EXPERIMENTATION, SETUP_SERVER_SIDE_EXPERIMENTATION } from './types';
import { NAV_EXPERMENTATION_STORE } from '../../types/names';

const handleServerSideExpermentation = (store) => onServerPrefetch(() => store.dispatch(`${NAV_EXPERMENTATION_STORE}/${SETUP_SERVER_SIDE_EXPERIMENTATION}`));
const handleClientSideExperimentation = (store) => {
  onMounted(() => {
    store.dispatch(`${NAV_EXPERMENTATION_STORE}/${SETUP_CLIENT_SIDE_EXPERIMENTATION}`);
  });
};

/* eslint-disable import/prefer-default-export */
export function useInitExperimentation() {
  const store = useStore();
  handleServerSideExpermentation(store);
  handleClientSideExperimentation(store);
}
