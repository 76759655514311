// TODO add to documentation that tells you to modify this to show ks
const dashboardKillswitchConfig = {
  // Example:
  // isStickyHeaderEnabled: {
  //   description: 'Makes header sticky when scrolling',
  // },
};

const KILLSWITCH_NAMES = {
  // example for local killswitch:
  // navTestKs: 'navTestKs',
  showMinimalSearch: 'showMinimalSearch',
  registryDropdownEnabled: 'registryDropdownEnabled',
  hmacKey: 'hmacKey',
  xMacysApiKey: 'xMacysApiKey',
  persistentLoginEnabled: 'persistentLoginEnabled',
  enhancedMobileNavEnabled: 'enhancedMobileNavEnabled',
  enhancedDesktopNavEnabled: 'enhancedDesktopNavEnabled',
  useCommonSearchPageUrlPattern: 'useCommonSearchPageUrlPattern',
  vueDealsEnabled: 'vueDealsEnabled',
  internationalContextEnabled: 'internationalContextEnabled',
  priceSimplificationEnabled: 'priceSimplificationEnabled',
  accountDropdownEnabled: 'accountDropdownEnabled',
  disableMediaRail: 'disableMediaRail',
  condensedHeaderEnabled: 'condensedHeaderEnabled',
};

const localKillswitches = {
  // Example:
  // [KILLSWITCH_NAMES.navTestKs]: true,
};

const ALLOWED_KILLSWITCHES = {
  // example for local killswitch:
  // [KILLSWITCH_NAMES.navTestKs]: true,
  [KILLSWITCH_NAMES.showMinimalSearch]: true,
  [KILLSWITCH_NAMES.registryDropdownEnabled]: true,
  [KILLSWITCH_NAMES.hmacKey]: true,
  [KILLSWITCH_NAMES.xMacysApiKey]: true,
  [KILLSWITCH_NAMES.persistentLoginEnabled]: true,
  [KILLSWITCH_NAMES.enhancedMobileNavEnabled]: true,
  [KILLSWITCH_NAMES.enhancedDesktopNavEnabled]: true,
  [KILLSWITCH_NAMES.useCommonSearchPageUrlPattern]: true,
  [KILLSWITCH_NAMES.vueDealsEnabled]: true,
  [KILLSWITCH_NAMES.internationalContextEnabled]: true,
  [KILLSWITCH_NAMES.priceSimplificationEnabled]: true,
  [KILLSWITCH_NAMES.condensedHeaderEnabled]: true,
  [KILLSWITCH_NAMES.accountDropdownEnabled]: true,
  [KILLSWITCH_NAMES.disableMediaRail]: true,
};

// we should not expose these killswtitches in SSR response due to security
const CLIENT_SIDE_ONLY_KILLSWITCHES = {
  [KILLSWITCH_NAMES.hmacKey]: true,
  [KILLSWITCH_NAMES.xMacysApiKey]: true,
};

module.exports = {
  dashboardKillswitchConfig,
  localKillswitches,
  ALLOWED_KILLSWITCHES,
  CLIENT_SIDE_ONLY_KILLSWITCHES,
  KILLSWITCH_NAMES,
};
