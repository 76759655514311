import { useStore } from 'vuex';
import { computed } from 'vue';
import { GET_HEADER_DATA } from '../types/getters';
import useLoyalty from './useLoyalty';

const promoMessageTypes = {
  regular: 'regular',
  international: 'international',
  bronze: 'bronze',
  silver: 'silver',
  gold: 'gold',
  platinum: 'platinum',
};

export default function usePromoMessage() {
  const store = useStore();
  const { rewards } = useLoyalty();
  const isInternational = computed(() => !store.state.pageData.navigation.context.isDomestic);
  const headerData = computed(() => store.getters[`headerStore/${GET_HEADER_DATA}`]);
  const type = computed(() => (isInternational.value
    ? promoMessageTypes.international
    : rewards.value.tierName || promoMessageTypes.regular));
  const media = computed(() => {
    const topNavigation = headerData.value?.topNavigation?.[0] || {};
    const { attributes = [] } = topNavigation;

    return attributes.reduce((acc, cur) => {
      const { name, value } = cur;

      return {
        ...acc,
        [name]: value,
      };
    }, {});
  });
  const promoMessage = computed(() => media.value[type.value] || '');
  const promoMessageLoaded = computed(() => Boolean(headerData.value));

  return { promoMessage, promoMessageLoaded, media };
}
