import { v4 as generateUUIDv4 } from 'uuid';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import EncBase64 from 'crypto-js/enc-base64';
import { Cookie } from '@common-vue/util/src/modules/cookie';

async function generatePersistentLoginHeaders() {
  const { Experimentation } = require('@common-vue/util/src/modules/experimentation');
  const { PersistentLoginUtil } = require('@common-vue/util/src/modules/persistentLogin');
  const experimentIds = Experimentation.getExpIds().toString();
  const isLogin365Customer = (Cookie.get('Login365Customer', 'MISCGCs') === 'Y') || false;

  return {
    'X-Macys-Experiment-Ids': experimentIds,
    'X-Macys-Login365Customer': isLogin365Customer,
    ...PersistentLoginUtil.getDeviceFingerPrintHeaders(),
  };
}

const generateHMACKey = (payload, hmacKey = '') => {
  const signature = JSON.stringify(payload);
  const signatureBytes = HmacSHA256(signature, hmacKey);
  const hmacValue = EncBase64.stringify(signatureBytes);
  return `MAC ${hmacValue}`;
};

const httpGetNonProtectedAPIHeaders = (properties = {}) => ({
  'x-macys-request-id': generateUUIDv4(),
  'x-macys-apikey': properties.xMacysApiKey,
  'x-macys-dca': Cookie.get('dca'),
  Accept: 'application/json',
});

const httpGetProtectedAPIHeaders = (envProps = {}, properties = {}) => ({
  macys_online_uid: encodeURIComponent(Cookie.get(envProps.isMcom ? 'macys_online_uid' : 'bloomingdales_online_uid')),
  'x-macys-securitytoken': encodeURIComponent(Cookie.get('secure_user_token')),
  ...(!envProps.hostlink?.includes('macys') && !envProps.hostlink?.includes('bloomingdales')) && { 'x-macys-client-host': envProps.hostlink?.split('.')[1] },
  ...httpGetNonProtectedAPIHeaders(properties),
});

const httpUpdateAPIHeaders = async (envProps = {}, hmacKeyPayload, properties = {}) => {
  const protectedHeaders = httpGetProtectedAPIHeaders(envProps, properties);
  const persistentLoginHeaders = await generatePersistentLoginHeaders();

  return {
    'Content-Type': 'application/json',
    HMAC: generateHMACKey(hmacKeyPayload, properties.hmacKey),
    ...protectedHeaders,
    ...persistentLoginHeaders,
  };
};

export {
  generatePersistentLoginHeaders,
  generateHMACKey,
  httpGetNonProtectedAPIHeaders,
  httpGetProtectedAPIHeaders,
  httpUpdateAPIHeaders,
};
