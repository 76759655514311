/* eslint-disable no-shadow */
import { viewTypes } from '../../constants';
import { SET_VIEW_TYPE } from '../../types/mutations';
import { GET_IS_MOBILE_VIEW, GET_IS_DESKTOP_VIEW } from '../../types/getters';

export function state() {
  return {
    viewType: null,
  };
}

export const getters = {
  [GET_IS_DESKTOP_VIEW]: (state) => state.viewType === viewTypes.desktop,
  [GET_IS_MOBILE_VIEW]: (state) => state.viewType === viewTypes.mobile,
};

export const mutations = {
  [SET_VIEW_TYPE](state, type) {
    state.viewType = type;
  },
};

export const actions = {};
