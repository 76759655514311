<template>
  <!-- eslint-disable vue/no-v-html -->
  <pre
    hidden
    v-html="metaData"
  />
  <!-- eslint-enable -->
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const metaData = computed(() => {
  const { isNavMockHeader, isNavMockFooter, version } = store.state.envProps;
  return JSON.stringify({ isNavMockHeader, isNavMockFooter, version }, null, 2);
});
</script>
