import { useStore } from 'vuex';

export default function useStoreSlideout() {
  const store = useStore();

  function toggleSlideout() {
    store.dispatch('navPreferredStore/toggleLocatorSlideout');
  }

  function openStoreSlideout() {
    toggleSlideout();
  }

  return {
    openStoreSlideout,
    toggleSlideout,
  };
}
