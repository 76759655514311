<template>
  <header
    class="mobile"
    :class="{'grid-container': isMcom}"
  >
    <slot />

    <div
      class="padding-top-xs padding-bottom-xs"
      :class="{'grid-container': !isMcom}"
    >
      <div class="grid-x align-justify">
        <div class="grid-x align-middle">
          <div class="grid-x margin-right-xs padding-left-z">
            <button
              ref="menuTarget"
              aria-label="menu"
              aria-haspopup="true"
              aria-controls="mobile-nav"
              :aria-expanded="isMenuOpened"
              class="menu-large menu-small"
              data-testid="burger-button"
              onclick="window.__NAVIGATION_MENU_CLICKED__ = true"
              @click.prevent="toggleNav"
            />
          </div>

          <Logo />
        </div>

        <div class="grid-x align-middle">
          <WishList
            v-if="isSignedIn && !experiments.accountDropdownEnabled"
            class="margin-right-xs"
          />
          <SignInLink
            v-else-if="isDomestic && !isSignedIn"
            v-show="mounted"
            class="mew-sign-in-btn margin-right-xs"
            :class="[{'primary': isMcom}, {'secondary': isBcom}]"
          />
          <BagIcon />
        </div>
      </div>

      <Search
        :model-value="searchValue"
        @onFocus="handleSearchFocus"
      />
    </div>

    <MobileMenu />
  </header>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useMounted } from '@vueuse/core';
import MobileMenu from './MobileMenu.common';

import Logo from '../common/Logo.common';
import BagIcon from '../common/BagIcon.common';
import WishList from '../common/WishList.common';
import Search from '../search/Search.common';
import SignInLink from '../common/SignInLink';
import { useRegistry } from '../../composables/useRegistry';
import {
  onUserInit, onKsFlagsReady, onAllPassedEventsReady, onKsFlagsFetched,
} from '../../events';
import { FETCH_PREFERRED_STORE } from '../../types/actions';
import { storeLoyaltyStatusForLegacy } from '../../composables/useLoyalty';
import useExperiments from '../../composables/useExperiments';
import { useMobileNav } from '../../composables/useMobileNav';

const store = useStore();
const { isMcom, isBcom } = store.state.envProps;
const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);
const {
  menuTarget, isMenuOpened, toggleNav, closeNav,
} = useMobileNav();
const searchValue = computed(() => store.state.headerStore.searchValue);
const mounted = useMounted();
const { fetchRegistries } = useRegistry({ checkUpdates: true });
const experiments = useExperiments();

function handleSearchFocus() {
  if (isMenuOpened.value) {
    closeNav();
  }
}

function fetchLoyalPoints() {
  if (isSignedIn.value) {
    store.dispatch('navLoyaltyStore/fetchRewardsData');
  } else {
    storeLoyaltyStatusForLegacy({}, isMcom);
  }
}

const fetchPreferredStore = () => {
  store.dispatch(`navPreferredStore/${FETCH_PREFERRED_STORE}`);
};

onUserInit(fetchPreferredStore);
onAllPassedEventsReady(
  [onKsFlagsFetched, onKsFlagsReady, onUserInit],
  () => {
    fetchRegistries();
    fetchLoyalPoints();
  },
);
</script>

<style lang="scss" scoped brand="bcom">
.mew-sign-in-btn {
  padding: 5px 10px;
}
</style>

<style lang="scss" scoped brand="mcom">
.mew-sign-in-btn {
  padding: 6px 9px;
}
</style>
