<template>
  <span class="grid-y align-items-start">
    <span class="heavy-sm relative info-row-1"><slot name="title" /></span>
    <span class="small relative info-row-2"><slot name="text" /></span>
  </span>
</template>

<style lang="scss" brand="mcom" scoped>
.info-row-1 {
  top: 2px;
}

.info-row-2 {
  top: -2px;
}
</style>
