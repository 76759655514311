<template>
  <Link
    :href="signInLink.url"
    :tracking="signInLink.tracking"
    class="button"
    @click="useSignInClick({ tooltipTags: true })"
  >
    Sign In
  </Link>
</template>

<script setup>
import Links from '../../constants/Links';
import Link from './Link';
import useSignInClick from '../../composables/useSignInClick';

const signInLink = Links.account.signIn;
</script>

<style lang="scss" scoped>
.button {
  padding: 0 9px;
  height: 34px;
  font-weight: 500;
}
</style>
