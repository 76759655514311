/* istanbul ignore file */
import {
  ref, computed, watch, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { GET_OVERLAY_NAME, GET_ATTRS } from '../../types/getters';
import { LINK_RAIL_OVERLAY } from '../../constants/common/overlayNames';
import { onUserInit } from '../../events';
import useProvideFactory from '../dumb/useProvideFactory';

export const SIGN_IN_TOOLTIP_REPOSITORY = Symbol('SIGN_IN_TOOLTIP_REPOSITORY');

export function getSignInTooltipSharedState() {
  return ref(false);
}

export function useSignInTooltipProvide(useAsInject) {
  return useProvideFactory({
    key: SIGN_IN_TOOLTIP_REPOSITORY,
    factory: getSignInTooltipSharedState,
    useAsInject,
  });
}

export function useSignInTooltipActivator({ isSignedIn, isDomestic }) {
  const isActive = useSignInTooltipProvide(true);
  const store = useStore();

  function deactivate() {
    isActive.value = false;
  }

  onUserInit(() => {
    const attrs = store.getters[`headerStore/${GET_ATTRS}`];
    const isTooltipWasDisplayed = sessionStorage.getItem('signInTooltipWasDisplayed');

    if (isSignedIn.value) {
      sessionStorage.removeItem('signInTooltipWasDisplayed');
    }

    isActive.value = isDomestic.value && !isSignedIn.value && (attrs?.isPermanentSigninTooltip || !isTooltipWasDisplayed);
  });

  return { isSignInTooltipActive: isActive, deactivateSignInTooltip: deactivate };
}

export function useSignInTooltip() {
  const isActive = useSignInTooltipProvide(true);
  const store = useStore();
  const overlayName = computed(() => store.getters[`navOverlay/${GET_OVERLAY_NAME}`]);
  const attrs = store.getters[`headerStore/${GET_ATTRS}`];
  const isTransparent = ref(false);

  let timer = null;
  let startTime = null;
  let timeRemaining = 8000;

  function deactivate() {
    isActive.value = false;
  }

  function startTimer(time) {
    if (attrs?.isPermanentSigninTooltip) return;

    startTime = new Date();
    timer = setTimeout(deactivate, time);
  }

  function suspendTimer() {
    if (attrs?.isPermanentSigninTooltip) return;

    clearTimeout(timer);
    const timeElapsed = new Date() - startTime;
    timeRemaining -= timeElapsed;
  }

  function resumeTimer() {
    startTimer(timeRemaining);
  }

  function storeWatchState() {
    sessionStorage.setItem('signInTooltipWasDisplayed', true);
  }

  onMounted(() => {
    startTimer(timeRemaining);
    storeWatchState();
  });

  watch(overlayName, (cur, prev) => {
    if (attrs?.isPermanentSigninTooltip) {
      if (prev === LINK_RAIL_OVERLAY) {
        isTransparent.value = false;
      } else if (cur === LINK_RAIL_OVERLAY) {
        isTransparent.value = true;
      }
      return;
    }

    if (cur === LINK_RAIL_OVERLAY) {
      deactivate();
    }
  });

  return { isTransparent, suspendTimer, resumeTimer };
}
