import { moveFocusElement } from '../../utils/accessibility/navigation';

export const useSearchResultList = ({
  listEl = null,
} = {}) => {
  function onKeydownArrowDown() {
    if (!listEl?.value) {
      return;
    }

    moveFocusElement(listEl.value, 'next');
  }

  function onKeydownArrowUp() {
    if (!listEl?.value) {
      return;
    }

    moveFocusElement(listEl.value, 'previous');
  }

  return {
    onKeydownArrowUp,
    onKeydownArrowDown,
  };
};

export default useSearchResultList;
