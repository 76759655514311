import { Cookie } from '@common-vue/util/src/modules/cookie';
import { getChildProperty } from '@core/util/object/index';
import {
  format,
  CURRENCY,
  NUMBER,
} from '../../utils/TealiumFormattingUtil';
import { fireLinkTag } from './tagManager';

export function tagBagHover() {
  fireLinkTag({ event_name: 'minibag hover' });
}

export function tagBagClick() {
  fireLinkTag({ event_name: 'minibag click', link_name: 'minibag click' });
}

export function tagBagView() {
  fireLinkTag({ event_name: 'minibag view bag' });
}

export function tagRemoveFromBag(product = {}) {
  const { detail = {} } = product;
  const {
    flags = {}, name: productName = '', quantity = 0, registry = { },
  } = detail;
  const { registryUrl = '' } = registry;
  const getPrice = (priceObj) => {
    const { value: values } = priceObj;

    if (!values || !values.length) return '';

    return values[0].toString();
  };
  const getRemoveType = (flag) => (flag ? 'manual' : '');
  const tieredPrices = getChildProperty(product, 'pricing.price.tieredPrice') || [];
  const [priceObj = {}] = tieredPrices;
  const orgPrice = getPrice(priceObj);
  const price = tieredPrices.length > 1 ? getPrice(tieredPrices.pop()) : orgPrice;
  const subTotal = (parseFloat(price) * parseInt(quantity, 10)) || '';
  const urlFrags = registryUrl.split('/');
  const bagItemsCount = Cookie.get('CartItem', 'GCs');

  let bagEmptyFlag = '';

  if (bagItemsCount !== 'undefined') {
    bagEmptyFlag = bagItemsCount === '0' ? 'true' : 'false';
  }

  const payload = {
    event_name: 'bag update',
    product_color: format(getChildProperty(product, 'traits.colors.selectedColor.name')),
    product_id: format(product.id),
    product_name: format(productName),
    product_original_price: format(orgPrice, CURRENCY),
    product_prev_quantity: format(quantity, NUMBER),
    product_price: format(flags.gwpIndicator ? '0' : price, CURRENCY),
    product_quantity: format('0'),
    product_size: format(getChildProperty(product, 'traits.size.selectedSize.name')),
    product_sub_total: format(subTotal, NUMBER),
    product_pwp_remove_type: format(getRemoveType(flags.pwpIndicator)),
    product_gwp_remove_type: format(getRemoveType(flags.gwpIndicator)),
    product_registry_number: format(urlFrags.pop()),
    is_bag_empty: bagEmptyFlag,
  };

  fireLinkTag(payload);
}

export default {
  tagBagHover,
  tagBagClick,
  tagBagView,
  tagRemoveFromBag,
};
