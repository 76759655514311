import { get } from '../../utils/api';
import { isSSR } from '../../utils/isSSR';
import devProxyApiUrl from '../../utils/devProxyApiUrl';
import { buildHeaderRequestUrl } from '../../utils/headerHelpers';
import { buildFooterRequestUrl } from '../../utils/footerHelpers';
import useFetch from './useFetch';

export function buildNavigationUrl(pathName, options) {
  const { clientBaseUrl, serverBaseUrl } = options;
  const host = isSSR() ? serverBaseUrl : clientBaseUrl;
  const proxy = devProxyApiUrl(host);

  return proxy(pathName);
}

function buildHeaderUrl(params) {
  return buildNavigationUrl(buildHeaderRequestUrl(params), params);
}

function buildFooterUrl(params) {
  return buildNavigationUrl(buildFooterRequestUrl(params), params);
}

export function useHeaderFetch(options) {
  return useFetch(() => get(buildHeaderUrl(options)));
}

export function useFooterFetch(options) {
  return useFetch(() => get(buildFooterUrl(options)));
}

export function useKillSwitchesFetch(options) {
  return useFetch(() => get(buildNavigationUrl('/xapi/navigate/v1/header-footer/switches', options)));
}
