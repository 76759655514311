<template>
  <div />
</template>

<script setup>
/* istanbul ignore file */
import { useStore } from 'vuex';

import { isSSR } from '../../utils/isSSR';
import { IS_STATE_RESTORATION_FROM_DOM_ENABLED } from '../../utils/restoreStateFromDom';

const store = useStore();

if (isSSR() && IS_STATE_RESTORATION_FROM_DOM_ENABLED) {
  const data = store.state.footerStore.data.footer;
  delete data.footerLinks;
  delete data.legalLinks;
}
</script>
