<template>
  <LinkRailMenu>
    <LinkRailMenuItem>
      <Link
        class="link-rail-item"
        :href="Links.homepage.orderTracking.url"
        :tracking="Links.homepage.orderTracking.tracking"
      >
        Order Tracking
      </Link>
    </LinkRailMenuItem>
    <LinkRailMenuItem>
      <Link
        class="link-rail-item"
        :href="Links.homepage.stores.url"
        :tracking="Links.homepage.stores.tracking"
        @click="tagStoresLink"
      >
        Stores
      </Link>
    </LinkRailMenuItem>
    <LinkRailMenuItem>
      <Link
        class="link-rail-item"
        :href="Links.customerService.internationalRegistry.url"
        :tracking="Links.customerService.internationalRegistry.tracking"
        @click="tagRegistryLink"
      >
        Gift Registry
      </Link>
    </LinkRailMenuItem>

    <LinkRailMenuItem v-if="mounted && internationalContextEnabled">
      <button
        class="link-rail-item link-rail-shipping align-middle"
        @click.prevent="openInternationalSlideout"
      >
        <span>Shipping To</span>
        <Flag
          class="margin-left-xxs"
          width="16px"
          height="16px"
        />
      </button>
    </LinkRailMenuItem>
  </LinkRailMenu>
</template>

<script setup>
import { computed } from 'vue';
import { useMounted } from '@vueuse/core';

import useInternationalSlideout from '../../composables/features/useInternationalSlideout';
import { tagRegistryLink, tagStoresLink } from '../../features/analytics/linkRailTags';
import useKillswitches from '../../composables/useKillswitches';
import Links from '../../constants/Links';
import Link from '../common/Link';
import Flag from '../common/Flag.common';
import LinkRailMenu from './LinkRailMenu.common';
import LinkRailMenuItem from './LinkRailMenuItem.common';

const { openInternationalSlideout } = useInternationalSlideout();
const killswitches = useKillswitches();
const mounted = useMounted();

const internationalContextEnabled = computed(() => killswitches.value.internationalContextEnabled);
</script>

<style lang="scss" brand="mcom">
.link-rail-shipping {
  display: flex !important;
}
</style>
