<template>
  <a
    v-bind="$attrs"
    :href="href"
    :target="target"
    :rel="rel"
    @click="onClick"
  >
    <slot name="prefix" />
    <slot />
  </a>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import sendTrackParams from '../../features/analytics/sendTrackParams';

const store = useStore();
const { context } = store.state.pageData.navigation;

const props = defineProps({ // eslint-disable-line
  href: {
    type: [String, Function],
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  isOpenNewTab: {
    type: Boolean,
    default: false,
  },
  tracking: {
    type: Object,
    default: () => ({}),
  },
});

const phoneNumberRegex = /^([0-9])[-. ]\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

function onClick(event) {
  if (!Object.keys(props.tracking || {}).length) return;

  const { currentTarget: $target } = event;
  const linkText = $target.textContent.trim();
  const $header = document.getElementById('nav-header-root');
  const isDescendantOfHeader = !!$header?.contains($target);
  const trackingPayload = {
    clickable_element: linkText,
    primary_component: isDescendantOfHeader ? 'glbhdrnav' : 'glbbtmnav',
    ...props.tracking,
  };

  sendTrackParams(trackingPayload, context);
}

function sanitizeHref(href) {
  const match = href.match(/javascript:.+\(['"](.+)['"]\)/); // "javascript:pop('http://www.bloomingdalesjobs.com')" -> ["'http://www.bloomingdalesjobs.com'", "http://www.bloomingdalesjobs.com"]
  return match ? match[1] : href;
}

function getAbsoluteUrl(href) {
  const isAbsoluteUrl = /^(https?:\/\/)|(javascript:)/i.test(href);
  const baseUrl = store.state.envProps.hostlink || '';
  return isAbsoluteUrl ? href : `${baseUrl}${href}`;
}

const hrefString = computed(() => (typeof props.href === 'function' ? props.href(context) : props.href || '/#brokenUrl'));
const isPhoneNumberByAlt = computed(() => phoneNumberRegex.test(props.alt));
const isTellOrMailto = computed(() => /^tel:|mailto:/.test(hrefString.value));

const isScriptBasedUrl = computed(() => hrefString.value.includes('javascript:pop')); // eslint-disable-line no-script-url
const isOpenNewTab = computed(() => props.isOpenNewTab || isScriptBasedUrl.value);

const target = computed(() => (isOpenNewTab.value ? '_blank' : '_self'));
const rel = computed(() => (isOpenNewTab.value ? 'noopener noreferrer' : ''));

const href = computed(() => {
  if (isPhoneNumberByAlt.value) {
    return `tel:${props.alt.replace(phoneNumberRegex, '$1$2$3$4')}`;
  }
  if (isTellOrMailto.value) {
    return hrefString.value;
  }
  return getAbsoluteUrl(sanitizeHref(hrefString.value));
});
</script>
