<template>
  <footer
    class="footer-responsive align-center"
    :class="{ 'is-international': isInternational }"
  >
    <div class="row-1">
      <nav
        class="wrapper grid-container padding-top-l padding-bottom-l"
        aria-label="Primary footer section"
      >
        <FooterLinks />
        <StayConnected class="stay-connected" />
      </nav>
    </div>
    <div class="row-2">
      <nav
        class="grid-container padding-top-m padding-bottom-m"
        aria-label="Secondary footer section"
      >
        <PrivacyLinks :links="legalLinks" />
        <Copyright />
      </nav>
    </div>
    <SSRFooterLinksRemover />
  </footer>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import FooterLinks from './FooterLinks.common';
import StayConnected from './StayConnected.mcom';
import PrivacyLinks from './PrivacyLinks.common';
import Copyright from './Copyright.common';
import { GET_LEGAL_LINKS } from '../../types/getters';
import SSRFooterLinksRemover from './SSRFooterLinksRemover';

const store = useStore();
const isInternational = computed(() => store.state.pageData.navigation.context.isInternational);
const legalLinks = computed(() => store.getters[`footerStore/${GET_LEGAL_LINKS}`]);
</script>

<style lang="scss" brand="mcom" scoped>
@import "../../scss/variables.mcom.scss";
@import "../../scss/mixins.mcom.scss";

.footer-responsive {
  .stay-connected {
    margin-left: auto;
  }

  &.is-international {
    .row-1 {
      .wrapper {
        gap: map-get($grid-margin-gutters, 'large');

        @include breakpoint(large up) {
          .stay-connected {
            flex-grow: 1;
            flex-basis: 0;
          }
        }

        @include breakpoint(mlarge up) {
          .stay-connected {
            flex-grow: 1;
            flex-basis: 0;
          }
        }
      }
    }
  }

  .row-1 {
    background-color: $footer-background-color;

    @include breakpoint(large up) {
      .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: map-get($grid-margin-gutters, 'medium');

        .stay-connected {
          flex-basis: 317px;
        }

        :deep() {
          .footer-links-group {
            flex-grow: 1;
            flex-basis: 0;
          }
        }
      }
    }

    @include breakpoint(mlarge up) {
      .wrapper {
        gap: map-get($grid-margin-gutters, 'large');
        .stay-connected {
          flex-basis: 464px;
        }
      }
    }
  }

  .row-2 {
    background-color: $black;
  }
}
</style>
