import { get } from '../../utils/api';
import useFetch from './useFetch';
import devProxyApiUrl from '../../utils/devProxyApiUrl';

function buildUrl(isMcom) {
  if (isMcom) {
    return '/xapi/loyalty/v1/starrewardssummary?_origin=HnF';
  }
  return '/xapi/loyalty/v1/accountsummary?_origin=HnF';
}

export async function useLoyaltyPointsFetch({ isSignedIn, uid, guid, isMcom, killswitches, hostlink }) { // eslint-disable-line
  const proxy = devProxyApiUrl(hostlink);
  const headers = {
    'x-macys-signedin': isSignedIn ? 1 : 0,
    'x-macys-uid': uid,
    'x-macys-userguid': guid,
    'x-requested-with': 'XMLHttpRequest',
  };
  const { Experimentation } = await import('@common-vue/util/src/modules/experimentation');
  const isPersistentLoginExperimentEnabled = await Experimentation.isRecipeSelected('2065-21');
  let persistentLoginEnabledKS = killswitches?.persistentLoginEnabled;
  if (!isMcom) {
    persistentLoginEnabledKS = killswitches?.persistentLoginEnabled && isPersistentLoginExperimentEnabled;
  }
  if (persistentLoginEnabledKS) {
    const { PersistentLoginUtil } = await import('@common-vue/util/src/modules/persistentLogin');
    Object.assign(headers, PersistentLoginUtil.getDeviceFingerPrintHeaders());
  }

  const url = proxy(buildUrl(isMcom));

  return useFetch(() => get(url, { headers }));
}
