import { Cookie } from '@common-vue/util/src/modules/cookie';
import CacheManager from '@vue-util/cacheManager';

export const cleanSignInForDevMode = () => {
  Cookie.set('SignedIn', '0');
};

export const cleanRegistryInfo = () => {
  sessionStorage.removeItem('registryFetch');
  localStorage.removeItem('userRegistries');
};

export const cleanLoyaltyInfo = (isMcom) => {
  const srKey = `hnfsr_${Cookie.get(isMcom ? 'macys_online_uid' : 'bloomingdales_online_uid')}`;
  CacheManager.removePersistent('isLoyaltyUser');
  CacheManager.removePersistent(srKey);
};
