<template>
  <div
    id="nav-footer-root"
    @click="popupLinkHandler"
  >
    <BaseFooter
      v-bind="attrs"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onBeforeMount } from 'vue';

import { useInitFooter } from '../composables/useInit';
import { useInitExperimentation } from '../features/experimentation/useInit';
import { usePopupLink } from '../composables/features/usePopupLink';
import { GET_ATTRS } from '../types/getters';
import BaseFooter from './BaseFooter';
import { IS_STATE_RESTORATION_FROM_DOM_ENABLED, restoreFooterLinks } from '../utils/restoreStateFromDom';

const store = useStore();
const { popupLinkHandler } = usePopupLink();

const attrs = store.getters[`headerStore/${GET_ATTRS}`];
const { isMinimal = false } = attrs;

useInitExperimentation();

if (!isMinimal) {
  useInitFooter();
}

onBeforeMount(() => {
  // only run menu restoration for SSR pages
  if (window.__NAVIGATION_STATE__ && IS_STATE_RESTORATION_FROM_DOM_ENABLED && !isMinimal) {
    const performanceTimerName = '[navigation-wgl] footer parse time';
    console.time(performanceTimerName); // eslint-disable-line no-console
    const { footerLinks, legalLinks } = restoreFooterLinks();
    store.state.footerStore.data.footer.footerLinks = footerLinks;
    store.state.footerStore.data.footer.legalLinks = legalLinks;
    console.timeEnd(performanceTimerName); // eslint-disable-line no-console
  }
});
</script>
