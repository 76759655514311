const experimentConfig = require('./config');

const getScaledExperimentIds = () => experimentConfig.reduce((acc, { treatment, scaled }) => {
  if (scaled) {
    acc.push(treatment);
  }
  return acc;
}, []);

const getExperimentsMap = (experiments) => experiments.reduce((acc, cur) => {
  const [key, value] = cur.split('-');
  acc[key] = value || '';
  return acc;
}, {});

/**
 * Returns new array with the recipie added. Will remove any existing recipie id for the same experiment
 * @param {Array} arr - Array of recipie ids
 * @param {Array|String} expToAdd - Array of or single recipie id to add
 * @returns
 */
const addExperiment = (arr, expToAdd) => { // eslint-disable-line import/prefer-default-export
  const allExperiments = arr.concat(expToAdd);
  const experimentsMap = getExperimentsMap(arr.concat(allExperiments));

  return Object.entries(experimentsMap).map(([key, value]) => `${key}-${value}`);
};

module.exports = {
  addExperiment,
  getScaledExperimentIds,
};
