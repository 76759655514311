// This is for adding killswitches that don't (currently) exist in xapi
const { ALLOWED_KILLSWITCHES, CLIENT_SIDE_ONLY_KILLSWITCHES } = require('./config');

function combineKillswitches({ killswitches, localKillswitches, killswitchesOverride }) {
  const combinedKillswitches = { ...localKillswitches, ...killswitches, ...killswitchesOverride };

  return Object.entries(combinedKillswitches).reduce((acc, [key, value]) => {
    if (ALLOWED_KILLSWITCHES[key] && (!CLIENT_SIDE_ONLY_KILLSWITCHES[key] || typeof window !== 'undefined')) {
      acc[key] = value;
    }

    return acc;
  }, {});
}

function getClientSideOnlyKillswitches(killswitches) {
  return Object.entries(killswitches).reduce((acc, [key, value]) => {
    if (CLIENT_SIDE_ONLY_KILLSWITCHES[key]) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

module.exports = { combineKillswitches, getClientSideOnlyKillswitches };
