<template>
  <nav
    id="mobile-nav"
    ref="targetNav"
    class="mobile-nav paddin-bottom-xl"
    :class="{
      active: isMenuOpened,
      'mobile-nav--enhanced-m': enhancedMobileNavFeature,
      'mobile-nav--enhanced-d': enhancedDesktopNavFeature,
      'mobile-nav--animated': isAnimated,
      'mobile-nav--hydrated': isMenuHydrated || !isNavSSR,
    }"
    @keyup.esc="emits('close')"
  >
    <div
      class="mobile-nav-wrap"
      :style="isMcom && !enhancedMobileNavFeature ? contentWrapStyles : ''"
    >
      <slot />
    </div>
    <span
      v-if="mounted && !isMenuHydrated && isNavSSR"
      class="mobile-nav--loading"
      :class="{'mobile-nav--loading-show': isAnimated }"
    >Loading . . .</span>
    <button
      v-if="!enhancedDesktopNavFeature"
      aria-label="Close menu"
      data-testid="close-button"
      class="mobile-nav-close icon-button-close align-middle align-center"
      @click="emits('close')"
    >
      <span class="close-small" />
    </button>
  </nav>
</template>

<script setup>
/* istanbul ignore file */

import {
  ref, computed, onMounted, onUnmounted, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useResizeObserver, onKeyStroke, watchOnce } from '@vueuse/core';
import { moveFocusElement } from '../../utils/accessibility/navigation';
import useFeatureEligibility from '../../composables/useFeatureEligibility';
import { useMobileNav } from '../../composables/useMobileNav';
import scrollToTop from '../../utils/scrollToTop';

const store = useStore();
const { isMcom, isNavSSR } = store.state.envProps;
const { enhancedMobileNavFeature, enhancedDesktopNavFeature } = useFeatureEligibility();
const { isMenuOpened, isMenuHydrated } = useMobileNav();

const targetNav = ref(null);
const isAnimated = ref(false);
const mounted = ref(false);
const headerHeight = ref(0);
const contentWrapStyles = computed(() => ({
  height: enhancedDesktopNavFeature.value
    ? `calc(100vh - ${headerHeight.value})`
    : `calc(100% - ${headerHeight.value})`,
}));
const emits = defineEmits(['close']); // eslint-disable-line no-undef
const moveFocusNext = () => moveFocusElement(targetNav.value, 'next', true);
const moveFocusPrevious = () => moveFocusElement(targetNav.value, 'previous', true);

onMounted(() => {
  mounted.value = true;

  const $header = document.getElementById('nav-header-root');

  onKeyStroke(true, (e) => {
    if (!e.shiftKey && e.code === 'Tab') {
      e.preventDefault();
      return moveFocusNext();
    }
    if (e.code === 'ArrowDown' || e.code === 'ArrowRight') {
      e.preventDefault();
      return moveFocusNext();
    }
    if (e.code === 'ArrowUp' || e.code === 'ArrowLeft') {
      e.preventDefault();
      return moveFocusPrevious();
    }
    if (e.shiftKey && e.code === 'Tab') {
      e.preventDefault();
      return moveFocusPrevious();
    }

    return null;
  }, { target: targetNav });

  function getHeaderHeight(entries) {
    const { target } = entries[0];
    headerHeight.value = `${target.offsetHeight}px`;
  }

  watch(isMenuOpened, (val) => {
    if (val) {
      setTimeout(() => { // Not sure how to do this without a timeout
        // move focus to first menu item instead of close button
        moveFocusNext();

        // reversing item's focus (eg: top nav search field) to it's wrapper of tabindex -1.
        // this help prevent search bar from focusing when sideNav is visible
        moveFocusPrevious();
        scrollToTop('.mobile-nav-wrap');
      }, 0);
    }
  });

  // TODO: remove header height calc if experiment "enhancedMobileNavFeature" is successful
  const { stop } = useResizeObserver($header, getHeaderHeight);
  onUnmounted(stop);
});

watchOnce(isMenuOpened, () => {
  isAnimated.value = true;
});
</script>

<style lang="scss">
$max-width: 350px; // helps meet design requirement with nav width maxof 350px
$min-width: 280px;
$width: 100%;
$animation: 0.25s cubic-bezier(0.4, 0, 0.2, 1);

.mobile-nav {
  background: $white;
  border-right: $hr-gray-divider;
  height: 100%;
  left: 0;
  max-width: rem-calc($max-width);
  min-width: rem-calc($min-width);
  position: fixed;
  transform: translateX(-100%);
  width: calc($width - 50px);
  z-index: 21;

  &--animated {
    transition: transform $animation;
  }

  &--loading {
    position: absolute;
    right: 27px;
    top: 27px;
    font-size: 14px;
    color: $gray-3-color;
    opacity: 0;
    transition: 0.2s 0.8s linear; // delay the transition for 800ms, most of the time the loading text is not shown. it's for slow connections

    &-show {
      opacity: 1;
    }
  }

  & > div {
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  &--hydrated.active {
    & > div {
      opacity: 1;
    }
  }

  &.active {
    transform: translateX(0);

    .mobile-nav-close {
      display: flex;
      height: rem-calc(32);
      width: rem-calc(32);
    }
  }

  h5 {
    font-weight: 500;
    text-transform: capitalize;
  }
}

.mobile-nav--enhanced-m {
  h5 {
    font-size: rem-calc(18);
    line-height: rem-calc(22);
    text-transform: none;
  }
}

.mobile-nav-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.mobile-nav-close {
  background: white;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  left: calc($width + 9px);
  position: absolute;
  top: rem-calc(20);
  z-index: 10;
}

$width-enhanced: 390px;

.mobile-nav--enhanced-d {
  border-right: none;
  max-width: fit-content;
  min-width: $width-enhanced;
  position: absolute;
  background-color: $white;
  opacity: 0;

  &--animated {
    transition: transform $animation, opacity $animation;
  }

  &.active {
    opacity: 1;
  }

  &.mobile-nav {
    top: 1px;
  }
}
</style>

<style lang="scss" brand="mcom">
.mobile-nav {
  top: auto;
}

.mobile-nav--enhanced-m {
  top: 0;

  .mobile-nav-wrap {
    height: 100%;
  }
}

.mobile-nav--enhanced-d {
  .mobile-nav-wrap {
    height: 100%;
  }
}
</style>

<style lang="scss" brand="bcom">
.mobile-nav {
  top: 0;

  &.active {
    .mobile-nav-close {
      height: rem-calc(24);
      width: rem-calc(24);
    }
  }
}

.mobile-nav-wrap {
  height: 100%;
}
</style>
