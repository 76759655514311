import { fireTag } from '@common-vue/util/src/modules/tagManager';

export function fireLinkTag(payload) {
  fireTag('link', payload);
}

export function fireViewTag(payload) {
  fireTag('view', payload);
}

export default { fireLinkTag, fireViewTag };
